.resetMessage{
  margin-top:10px;
  font-size: 19px;
}

.btnFilterSearch.resetHome{
  max-width: 200px;
  text-align: center;
  

  &:hover{
    background-color:#586a80;
    color:#fff;
  }
}