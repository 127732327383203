.baslangicWrapperNew{
  display:flex;
  justify-content: center;
  width: 100%;
  position:relative;
  flex-direction: column;
  padding-bottom:160px;

  .greenLine{
    position:relative;
    display:block;
    width: 100%;
    height:5px;
    background:#84ac87;
    
  
  }

  .baslangicContentWrapper{
    display:flex;
    position: relative;
    width:100%;
    height:100%;
    margin-top:55px;
    background: #f6f5f3;
    padding-bottom:133px;


    .baslangicContent{
      display:flex;
      flex-direction: column;
      max-width: 980px;
      width:100%;
      margin-left:auto;
      margin-right: auto;
      justify-content: center;
      align-items: center;
      padding:78px;
      color:#000;

      p{
        font-family: 'Baskerville BT',serif !important;
        // font-family: "Baskervville", serif;
        font-size: 1.5rem !important;
        
        color:#000;
        text-shadow: 0 0 #000;
        
        text-align: center;
        margin-bottom:5px;
    
        strong{
          font-family: 'Baskerville BT',serif !important;
          // font-family: "Baskervville", serif;
          color:#000;
          text-shadow: 0 0 #000;
        }
    
        u{
          // font-family: 'Baskerville BT',serif !important;
          font-family: "Baskervville", serif;
          color:#000;
          text-shadow: 0 0 #000;
    
        }
    
    
      }
    
      .btnBaslaNew{
        max-width: 313px;
        justify-content: center;
        align-self: center;
        margin-top:20px;
        padding: 0px;
        width:100%;
        height: 88px;   
        position: relative;
        bottom: 0px;
        z-index: 2;     
        border-radius: 0;
        background:#506c53;
        font-size:2rem;
        font-family: 'Baskerville BT',serif !important;
        font-weight: normal;
        margin-top:73px;

        &:hover{
          cursor: pointer;
          background:#3b503e !important;
          color:#fff;
        }
      }
  
    }

  
  
  }

}

