.girisYaziSuccess{
  font-size: 28px !important;
  font-weight: 400 !important;
  color: #506c53 !important;
  text-shadow: 0 0 #506c53;
  line-height: 30px !important;
  /* margin-top: 20px; */
  margin-bottom: 90px !important;
  display:flex;
  flex-direction: column;
  align-items:center;
  text-align: center;
  
  span{
    font-size: 21px;
    margin-top:10px;
    margin-bottom:0px;
    text-align: center;

    &:first-of-type{
      margin-top:40px;
    }
  }

}

.sectionWrapperSuccess{
  display:flex;
  justify-content: center;
  margin-left:auto;
  margin-right: auto;
}