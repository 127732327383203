.footerSignup{
  height: 65px;
  width: 100%; 
  display: flex;
  box-sizing: border-box;
  position:relative;
  bottom:10px;
  padding-left:0px;
  padding-right:0px;     
  justify-content: space-between;
  margin-top:100px;
}


.nextButton{
  position:relative;
  fill:#fff;
  width:31px;
  height:37px;
  margin-top:5px;
}
.nextButtonDone{
  fill:#fff;
}

.nextButtonLinkWrapper{
  display:flex;
  position:relative;
  align-items: center;
  justify-content: space-around;
  text-decoration: none;
  width:174px;
  height:88px;
  margin:0px !important;
  background:#6e6f6e !important;
  padding-left:20px;
  padding-right:20px;
  padding-top:5px;
}

.nextButtonLinkWrapper:hover{
  background:#506c53 !important;

  .nextButtonText{
    color:#fff;
  }

  .nextButton{
    fill:#fff;
  }

  .nextButtonDone{
    fill:#fff; 
  }

}

.nextButtonText{
  font-family: "Baskerville BT",serif;    
  color:#fff;
  text-decoration: none;
  font-weight: 500;
  font-size:2rem;
  margin:0px;
  margin-right:10px;
}
.nextButtonTextDone{
  margin-right:10px;
}






.backButton{
  position:relative;
  fill:#fff;
  width:31px;
  height:37px;
  margin-top:5px;  
}
.backButtonDone{
  fill:#fff;
}

.backButtonLinkWrapper{
  display:flex;
  align-items: center;
  text-decoration: none;
  width:174px;
  height:88px;
  background:#6e6f6e !important;
  padding-left:20px;
  padding-right:20px;
  padding-top:5px;  
}

.backButtonLinkWrapper:hover{
  background:#506c53 !important;
  .backButtonText{
    color:#fff;
  }

  .backButton{
    fill:#fff;
  }

  .backButtonDone{
    fill:#fff; 
  }

}

.backButtonText{
  font-family: "Baskerville BT",serif;    
  color:#fff;
  text-decoration: none;
  font-weight: 500;
  font-size:2rem;
  margin:0px;
  margin-left:13px;
}
.backButtonTextDone{
  margin-left:10px;
}


.btntamamla{
  margin-right: 0px;
}