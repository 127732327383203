.faqSectionWrapper{
  display: flex;
  background:#efedee;
  width: 100%;
  height:100vh;
  padding-top:191px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */  

  .faq__question-button{
    font-family: 'Baskerville BT',serif;
    font-size:19px;
    color:#384a60;
  }


  .webSectionTitle{
    margin-top:0px;
    margin-bottom:20px;
    font-family: 'Baskerville BT',serif;
  }

  .faqWrapper{
    display:grid;
    grid-template-columns:300px 1fr;
    grid-template-rows: 1fr;   
    grid-gap:30px;
    width: 100%;
    height: 100%; 
    max-width: 1336px;   
    padding:30px;
    font-size:1.3rem;
    
  }

  .faqContent{
    p{
      color:#384a60;
      font-size:19px;
      font-family: 'Baskerville BT',serif;
    }
    a{
      color:#384a60;
    }
    
  }




  .faqTitle{
    color:#384a60;
    font-family: 'Baskerville BT',serif;
  }

  .ulFaqList{
    list-style: none;
    font-size:1.3rem;

    li{
      color:#384a60;
      font-family: 'Baskerville BT',serif;
      font-weight: 500;

      &.selected{
        color:#384a60;
        font-weight: bold;
      }

      &:hover{
        color:#384a60;
        font-weight: bold;
        cursor:pointer;
      }
    }
  }

}

.subContentSectionFAQWrapper{
  display: flex;
  flex-direction: column;
  align-items:center;
  width:100%;
  height:100%;
  padding:60px;


  .subContentTitleFAQ{
    color:#84ac87;
    font-family: "Baskerville BT",serif;
    font-style: italic;
    font-size: 4rem;
    line-height: 4rem;
    text-shadow: none;
    margin-bottom:50px;
    text-transform: uppercase;
    
  }
  .subContentTitle2{
    color:#84ac87;
    text-shadow: none;
    font-family: "Baskerville BT",serif;
    font-style: italic;
    font-size: 4rem;
    line-height: 4rem;
    margin-top:60px;
    margin-bottom:60px;
  }

  .subContentParagraph2{
    font-family: "Baskerville BT",serif;
    font-size:1.3rem;
    line-height: 2rem;
    color:#000000;
    font-weight: lighter;
    text-shadow: 0 0 #000;      
    max-width: 1255px;
    width:100%;
    text-align: left;
   
    word-wrap: break-word;

    b{
      font-weight: bold;
      color:#000;
    }

    margin-bottom:130px;

  }

  .ulProjeEkibi{
    display:flex;
    flex-direction: column;
    list-style: none;
    padding:0px;
    margin:0px;
    margin-bottom:100px;

    li{
      font-family: "Baskervville", serif;
      font-size:1.8rem;
      line-height: 1.8rem;
      color:#000000;
      font-weight: lighter;
      text-shadow: 0 0 #000;            
      margin-bottom:10px;

      &:hover{
        cursor:pointer;
        color:#84ac87;
      }

      &.selected{
        color:#84ac87;
        text-shadow: 0 0 #84ac87;  
      }

    }

  }


}