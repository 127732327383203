.expertProfileNew{
  padding-top:80px !important;
  padding-left:125px !important;
  padding-right:125px !important;
  padding-bottom:80px !important;
  background:#f6f5f3;

  .profileTitle{
    font-family: "Montserrat", sans-serif;
    text-decoration: underline;
    color:#647b66;
    font-size:1.4rem;
    letter-spacing: 3px;

  }

  .ulYayinReferanslar {
    li{
      span{
        color:#3f5642 !important;
      }
    }
    
  }

  .socialMediaAccounts{
    .btn-remove-list {
      background: none !important;
    }

    .btn-remove-list-icon{
      max-height: 12px;
      display:block;
    }

  }

  .buttonUpload{
    box-shadow: unset !important;
  }


  .btnCustomUpload{
    position:absolute;
    bottom:-100px;
    left:0;
    right:0;
    margin:auto;
    font-family: "Baskerville BT", serif;

    background-color: #84ac87;

    &:hover{
      background-color:#506c53;
      color:#fff;
    }      

  }

  .btnFilterSearch.resetHome{
    max-width: 240px;
    text-align: center;
    background-color: #84ac87;
    margin-left:0px !important;
  
    &:hover{
      background-color:#506c53;
      color:#fff;
    }
  }

  .btnFilterSearch.save{
    background-color: #84ac87;


    &.profilesave{
      width:200px;
    }

    &:hover{
      background-color:#506c53;
      color:#fff;
    }    
  }
  .btnFilterSearch.addButton{
    background-color: #84ac87;
    height:85px;
    margin-top:0px;
    &:hover{
      background-color:#506c53;
      color:#fff;
    }    
  }


  .socialSelect {
    position:relative !important;
    width: 130px;
    height:85px;
    border:1px solid #c2c1bd;
    outline-color: #3f5642;
    margin-right:10px !important;
  }
  .margin-left-social-link{
    
  }

  .ant-upload-list-picture-card{
    width: 345px !important;
    height: 375px !important;
  }
  .ant-upload.ant-upload-select-picture-card{
    position: absolute;
  }

  .ant-upload-list-item-list-type-picture-card{
    max-width: 152px !important;
    max-height: 152px !important;
  }

  .ant-upload-list-picture-card-container{
    width: 345px !important;
    height: 375px !important;
    z-index: 99;
    display: flex;
    background: #e8e0db;
    justify-content: center;
  }

  .ant-upload-select{
    width: 345px !important;
    height: 375px !important;    
    border-radius: 0 !important;
    border:8px solid #84ac87 !important;
  }

  .ulexpertList{
    justify-self: center;
    align-self: center;
  }

  .edits{
    margin-top:0px;
    margin-bottom:0px;
  }
  .memos{
    margin-top:0px;
    margin-bottom:0px;
    max-width: 400px;
  }

  .contentInfoWrapper{
    max-width: 100% !important;
  }

  .profileIntroUl li.profileItem{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom:20px;   
    
    .react-dropdown-select {
      margin-top: 0px;
      border-radius: 0px;
      border: 1px solid #3f5642;
      outline-color: #3f5642 !important;
      font-size: 1.7rem;
      background: #fff;
      height: 85px;
    }

    .checkContainer{
      margin-top:10px;
    }
  
    &.yasadigiulke{
      .react-dropdown-select-input {
        // display:none;
      }

      .react-dropdown-select{
        // height:40px;
        padding-left:15px;
      }
    } 

    .medyaDeneyimiRadioGroup{
      margin-top:0px;
      margin-bottom:0px;
      justify-content: flex-start;
    }   
    
    .medyaGroupNew{
      align-items: flex-start !important;
    }

    .linkInfo{
      bottom:7px;
      left:-5px;
    }

    .contentInfoWrapper{
      min-height: 40px !important;
    }


    .iletisimBilgileriCheckGroupPres {
      display: grid;
      grid-template-columns: minmax(200px,600px) 200px;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas:
          "formBox0 formBox1"
          "formBox4 formBox5"
          "formBox8 formBox9";
      grid-gap: 5px;
      height: 280px;
      width: 100% !important;

      .formBox1{
        justify-content: center;

        h5{
          font-weight: bold;
        }


      }

      .formBox5{
        justify-content:center;
      }
      .formBox4{
        justify-content:flex-start;
      }
      .formBox8{
        justify-content:flex-start;
      }

    }

    .sectionTitle{
      font-size: 2rem;
      text-align: left;
      color:#506c53;

    }

    .infoAltyapi{
      max-width: 600px;
    }

    .checkContainer{
      margin-top:0px;
    }

    .iletisimBilgileriCheckGroup{
      width: auto;
    }

    .groupContacts2{
      justify-content: flex-start;
    }

    .groupContacts{
      .edits{
        max-width: 272px !important;
      }
    }

  }
  
  .baglantiWrapper{
    position:relative;
    width:100%;
    margin-top:0px;
    margin-bottom:10px;
    align-items: flex-start !important;

    .baglantiWrapperSubContent{
      display:flex;
      width: 100%;
    }

  }

  .ulYayinReferanslar {
    list-style: none;
    position: relative;
    width: 100%;
    height: 100% !important;
    overflow: hidden;
    overflow-y: auto;
    height: 200px;
    margin: 0px;
    margin-left:0px !important;
  }


  .gorus-konular{
    padding-left:10px;
    padding-right: 10px;
  }


  .ant-upload-picture-card-wrapper {
    
  }

  .btnFilterSearch{
    &:hover{
      cursor:pointer;
    }
  }

 
  .margin-bottom-10{
    margin-bottom:10px;
  }
  
}