// @media screen and (max-width:450px){
//   .sliderWrapper{
//     flex-direction: column !important;
//     align-items: center !important;
//     justify-content: space-around !important;    
//   }
// }
// @media screen and (max-width:700px){
//   /* Chat Section Wrapper*/
//   .chatFormWrapper-content{
//     border-radius: unset;
//   }

//   .modalForm{

//     .chatSectionWrapper{
//       display: flex;
//       width: 100%;
//       height: calc(100% - 400px);
//       flex-direction: column;
//       grid-template-columns: 250px 1fr;
//       grid-template-rows: 100%;
//       grid-template-areas: "contacts messages";
//       grid-column-gap: 10px;
//       margin-top: 10px;   
      
//       .chatContacts{
//         height: 150px;
//       }

//       .chatMessages{
//         height: calc(100vh - 370px);
//         margin-top:10px;
//         min-height: 200px;
//       }
      
//     }
  

//   }

//   /* End of Chat section Wrapper */


//   /* Home Page */
//   .filterSectionWrapper .womanExpertSearchButtonWrapper {
//     height: 90px;
//     width:258px;
    

//     button{
//       h3{
//         font-size:19px;
//         line-height: 21px;
//         width:164px;
//       }
//     }

//   }  
//   .filterSectionWrapper .womanExpertJoinButtonWrapper {
//     height: 90px;
//     width:258px;
    
//     button{
//       h3{
//         font-size:19px;
//         line-height: 21px;
//         width:164px;
//         margin-top:-20px;
//       }
//     }    
//   }
//   .filterBox1{
//     flex-direction: column;
//     align-items: center;
//   }

// }
@media screen and (max-width: 960px) {

  /*Home Page*/

  main{
    position: relative;
    // display:grid;
    padding:10px;
    padding-top:85px;
    
  }

 

  .homeWrapper{
    padding-top:20px;
  }
  
  .navsub-menu-sub{

    .nav-item-sub:not(:last-child){
      display:none;
    }
    
  }

  .sessizOlmazLogo{
    max-width: 280px;
  }

  #navsubID{
    .logoContainer{
      top:10px;
      padding:0px;

      img{
        width: auto;
        max-width: 100% !important;
        

      }
    }

  }

  .font-weight-bold{
    color:#506c53 !important;
  }




  .navbarhome{

    position:fixed;
    width:100%;
    height: 85px;
    z-index:4;
    padding-bottom: 0px;
    margin-top:0px;

    .navbarhome-container {
      display: flex;
      position: relative;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      .logoContainer{
        padding: 0px;
        top:0px;
        margin-top:10px !important;
    
        .navbarhome-logo{
          height: 75px !important;
        }
    
  
      }
  

    }  
    
    .nav-menu-sub-left{
      grid-template-columns: repeat(1, auto);
      max-width: 50px;
      justify-content: center;
      display:none;

      .nav-item-sub-left{
        left:0px;
        &:last-of-type{
          display: none;
        }
      }
    }

    .nav-menu-sub-right{
      grid-template-columns: repeat(1, auto);
      max-width: 50px;
      justify-content: center;
      display:none;

      .nav-item-sub-right{
        left:0px;
        &:last-of-type{
          display: none;
        }
      }
    }    


    .nav-menu-sub{
      grid-template-columns: repeat(1, auto);
      max-width: 50px;
      justify-content: center;
      display:none;

      .nav-item-sub{
        &:nth-of-type(2){
          display: none;
        }
        &:last-of-type{
          display: none;
        }
      }
    }

  }

  .navsubbarhome{

    position:fixed;
    width:100%;
    height: 67px;
    z-index:4;

    .navsubbarhome-container {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      height: 67px;
      width: 100%;

      .logoContainer{
        padding: 30px;
        padding-bottom: 15px;
        padding-top: 45px;
    
        .navsubbarhome-logo{
          width:200px;
          max-width: 200px;
        }
    
  
      }
  

    }  
    
    .nav-menu-sub-left{
      grid-template-columns: repeat(1, auto);
      max-width: 50px;
      justify-content: center;
      display:none;

      .nav-item-sub-left{
        left:0px;
        &:last-of-type{
          display: none;
        }
      }
    }
    .nav-menu-sub-right{
      grid-template-columns: repeat(1, auto);
      max-width: 50px;
      justify-content: center;
      display:none;

      .nav-item-sub-right{
        left:0px;
        &:last-of-type{
          display: none;
        }
      }
    }


    .nav-menu-sub{
      grid-template-columns: repeat(1, auto);
      max-width: 50px;
      justify-content: center;
      display:none;

      .nav-item-sub{
        &:nth-of-type(2){
          display: none;
        }
        &:last-of-type{
          display: none;
        }
      }
    }

  }

  .mainMenuWrapper{
    display:none;
  }




  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    padding-top:50px;
    padding-right: 0px;
  }

  .nav-menu.active {
    background: #506c53;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    overflow-y:auto;
  }  


  .nav-links-main {
    text-align: center;
    color:#fff;
    padding: 1rem;
    width: 100%;
    display: table;
    margin-right:0px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
  }

  .nav-links-main:hover {
    background-color: #ffffff;
    color: #384a60;
    border-radius: 0;
  }  

  .sliderWrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    
    height: auto;
    background-size: cover;
    margin-top:0px;
    background-repeat: no-repeat;
    background-position: center;
    padding-left:10px;
    padding-right:10px;
    padding-top:30px;
    background-position: bottom;
    background-size:90%;
    padding-bottom:270px;

    .btnWatchVideo{
      position: relative;
      max-width: 315px;
      width: auto;
      height: 50px;
      left:0px;
      padding:5px;
      display: flex;
      justify-content: space-around;
      bottom:20px;
      font-size: 14px;

      img{
        max-height: 16px;
        margin-left:10px;
      }

    }

    .sliderAttachLeft{
      width:25px;
      height:16px;
      background-size: contain;
      background-repeat: no-repeat;
      top:-10px;
    }
    .sliderAttachRight{
      width:25px;
      height:16px;
      background-size: contain;
      background-repeat: no-repeat;
      bottom:-10px;
    }
    

    .sliderQuoteWrapper{
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      bottom:unset;
    }

    .sliderQuote{
      display: flex;
      align-items: center;
      text-align: center;
      width: auto;
      font-size:2rem;
      line-height: 2rem;
      margin-bottom: 4px;
      padding:10px;
      font-weight: bold;
      
      
    }
  }


  .filterSectionWrapper{

    padding-bottom:0px;
    padding:20px;
    align-items: flex-start;
    height:auto;


    .filterSectionTitle{
      font-size:1.5rem;
      text-align: center;
    }
    .filterWrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-height: unset;
      // min-height: 400px;
      padding-top: 0px;
    }
    
    .react-dropdown-select{
      margin-top:0px;
    }

    .btnFilterSearch{
      margin-left:0px;
    }


    .filterGridWrapper{
      width:100%;
      flex-direction: column;
      
      height:auto;
      
      .middleRedBox{
        margin-bottom:10px;
        font-size:1.6rem;
        width:100%;
        margin-right:0px !important;
      }

      .filterBox1{
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        max-width: unset;
      }
      .filterBox2{
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        margin-top:20px;
        max-width: unset;
      }
      .filterBox3{
        display:flex;
        width:100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .inputWrapper.web{
          margin:0px;
          margin-top:10px;
        }

      }
      .filterBox4{
        display:block;
        width:100%;
        height: 100%;

        .inputWrapper.web{
          
          div{
            &:first-of-type{
              width: 100%;
              max-width: 400px;
            }
          }
          
        }

      }
      .filterBox5{
        display:block;
        width:100%;
        height: 100%;

        .inputWrapper.web{

          div{
            &:first-of-type{
              width: 100%;
              max-width: 400px;
            }
          }
          
        }

      }
      .filterBox6{
        display:block;
        width:100%;
        height: 100%;

        .buttonwrapper{
          display:flex;
          justify-content: center;
        }


      }

      .womanExpertSearchButtonWrapper {
        height: 90px;
        width:100%;
        display: flex;
        justify-content: center;
        
    
        button{
          justify-content: center;
          h3{
            font-size:15px;
            line-height: 21px;
            width:100%;
            text-align: center;
          }
        }
    
      }  
      .womanExpertJoinButtonWrapper {
        display: flex;
        height: 90px;
        width:100%;
        justify-content: center;
        button{
          justify-content: center;
          h3{
            font-size:15px;
            line-height: 21px;
            width:100%;
            margin-top:-20px;
            text-align: center
          }
        }    
      }    

      .inputWrapper.web{
        padding:0px;

        .editfiltericon{
            left:80px;
            display: none;
        }

        .editsfilter{
          width:100%;
          max-width: 400px;
          margin-bottom:0px;
          font-size: 17px;
          font-weight: normal;
          outline-color: #FF004B;
        }
      }

      .editsfilter.withicon{
        background:url('/assets/images/filter-search-icon.png');
        background-position: center left 10px;
        background-repeat: no-repeat;
      }


    }

  }

  

  .mainWorksHeaderImg{
    max-width: 300px !important;
  }

  .footerWebWrapper{
    display:none;
  }

  .mainWorksWrapper{
    padding:10px;
    padding-top:42px;
    padding-bottom:42px; 

    .mainWorksHeader{
      margin-bottom:20px;
    }

    .mainWorksHeaderTitle {
      font-size:1.7rem !important;
    }

    .workshop-content-wrapper{
      height:auto !important;
    }

    .workshop-title-wrapper{
      height: unset;
    }

    .workshop-title{
      font-size:2.3rem !important;
      
    }

  }


  .webSectionTitle{
    text-align: center;
    line-height: 29px;
    margin-top:10px;
    font-size: 32px !important;
  }


  .aboutlayerSectionWrapper{
    display:block;
    padding-bottom: 80px;

    .aboutlayerRow{
      height: 100%;
    }

    .aboutlayerGridWrapper{
      display:flex;
      flex-direction: column;      
    }

  }

  .articleItem{
    margin-left:auto;
    margin-right: auto;
  }

  .articlesSectionWrapper{
    display: block;
    min-height: unset;
    padding:10px;
    padding-top:40px;
    padding-bottom:40px;

    .articleItemTitle{
      font-size:3rem !important;
    }

    .articlesBGWrapper{

      background-size: 102%;
      background-position: 85px 160px;
      max-height: unset;

      .articlesWrapper{
        display:flex;
        flex-direction: column;
        align-items: center;
        padding: 15px;

        .articleBox{
          max-width: 432px;
          margin-top: 20px;
          border:0px !important;
        }
      }
    }

    .articlesMoreWrapper{
      max-height: unset;
      display: flex;
      flex-direction: column;
      align-items: center;
      
      .articlesMoreWrapper{
        display:flex;
        flex-direction: column;
        align-items: center;

        .articleMoreBox{
          max-width: 432px;
          margin-top: 20px;
        }
      }

    }

  }


  .educationsSectionWrapper{
    display:block;
    padding-bottom:80px;


    .sessizOlmazLogo{
      max-width: 280px !important;
      margin-bottom:0px !important;
    }

    .educationFirstRow{
      height:100%;
    }

    .educationRow{

      height: 100%;

      .educationGridWrapper{
        display:flex;
        flex-direction: column;
        padding:30px !important;
        padding-top:70px !important;
        padding-bottom:70px !important;

        .educationImage{
          background-size: cover;
          background-position: bottom;
          min-height: 300px;          
        }

        .education{
          min-height: 330px !important;
          .educationContent{
            font-size:16px;
          }
        }
        .educationleft{
          height: auto !important;
        }
        .educationright{
          height: auto !important;
          margin-top:140px;
        }

      }
    }

  }


  .aboutSectionWrapper{
    

    .aboutRow {
      height: 100%;
    }

    .aboutGridWrapper{
      display: flex;
      flex-direction: column;
    }

    .aboutImage{
      height: 100%;
      min-height: 300px;
    }

  }

  .bottomLayerWrapper{
    margin-bottom: 0px;

    p{
      text-align: center;
      padding-left:10px;
      padding-right:10px;
    }
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: -18px;
    right: 0px;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .contentInfoWrapper.gorus-konular.country-city{
    height: 100% !important;
  }


  .cvsSectionWrapper{
    padding:20px;
    padding-top:100px;

    .cvsWrapper{
      display: flex;
      flex-direction: column;
      padding:0px;
    }
  }

  .searchSectionWrapper{
    padding:20px;
    padding-top:100px;


    .searchWrapper{
      display: flex;
      flex-direction: column;
      padding:0px;
    }    

    .searchListSticky{
      align-items: center;
      margin-top:20px;
    }
  }
  
  .expertSectionWrapper{
    padding:20px;
    padding-top:100px;


    .expertWrapper{
      display: flex;
      flex-direction: column;
      padding:0px;
    }    

    .expertListSticky{
      align-items: center;
      margin-top:20px;
    }    
  }

  .articlePageSectionWrapper{
    padding:20px;
    padding-top:100px;


    .articlePageWrapper{
      display: flex;
      flex-direction: column;
      padding: 0px;

      .articlePageContent{
        padding:0px;
      }

    }

  }  

  .faqSectionWrapper{
    padding-top:120px;

    .webSectionTitle{
      margin-bottom:0px;
    }

    .faqWrapper{
      display:flex;
      flex-direction: column;
      padding:0px;

      .faqContent{
        width: 100%;
      }
      .faq{
        width:100%;
        padding-top:0px;

        .faq__list{
          display:block;
          width: 100%;
          padding-right: 10px;
          padding-left: 10px;
        }
    

        .faq__question{
          max-width: 100%;
  

          dd,dt{
 
          }
          
        }

        .faq__question-button{
          width: 100%;

        }


        button{
          font-size: 16px;
          line-height: 21px;
        }

      }

    }  
  

  }
  
  .ozgecmisTitle{
    max-width: 280px;
  }
  .btnShowOzgecmis{
    max-width: 280px;
  }

  .newFooter{
    flex-direction: column;
    height:auto;
    padding:20px;

    .footerSection{
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .footerbar-logo{
      max-width: 300px;
    }

    .foot2{
      p{
        text-align: center;
        font-size:1.3rem !important;
      }
      
    }

    .foot3{
      .socialList{
        li{
          font-size:1.3rem;
          text-align: center;
        }
      }
    }

    .foot4{
      .faqWrapper{
        text-align: center;
        justify-content: center;
      }
    }


    .copyrightWrapper{
      span{
        text-align: center;
      }
      
    }


  }


  /* Sign Up*/
  .closeSignUp {
    color: #506c53 !important;
    font-weight: lighter;
    position: absolute;
    right: 15px;
    top: -10px;
    max-height: 25px;
  } 

  main.signup {
    height: 100%;
    display: flex;
    grid-template-rows: 97px 1fr;
    padding-top: 115px;
    background: #e8e0db;
    overflow-y: scroll;
    flex-direction: column;
    /* padding-top: 10px; */
  }  


  .nav-links {
  
    font-size:15px;
    width:unset;
  
  }

  .headerContentWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    padding-top: 20px;   
  }

  .headerContentWrapper > h2 {
    margin-top:0px;
  }  

  .navbar{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap:5px;
       
  }

  .topHeader{
    height: 100px;
  }

  .medyakayit{
   
    .topHeader{
      height: 40px;
    }

    .mainSection{
      margin-top:20px;
    }
    
  }


  .sectionWrapper{
    padding-top:0px;
  }


  .mainInfoWrapper{
    flex-direction: column;
    margin-top:40px;
    align-items: center;
    padding:20px;
  }

  .inputWrapper .edits{
    margin-top:0px;
  }
  .titleAdsoyad{
    margin-top:10px;
  }
  
  footer{
    position: relative;
    margin-top:10px;
    margin-bottom: 10px;
    bottom:15px;
  }

  .gridWrapper{
    display: flex;
    flex-direction: column;
  }

  .componentWrapper{
    margin-bottom:20px;
    min-width: 100% im !important;
  }



  .iletisimBilgileriCheckGroup{
    width:100%;
  }


  .groupContacts{
    flex-direction: column;
  }

  .contactWrapper .checkContainer{
    min-width: unset;
  }

  .groupContacts2{
    flex-direction: column;
  }

  .mainSection{
    padding:20px;
    margin-top:40px;
  }

  .gorus-konular{
    min-width: 100% !important;
  }
  .contentInfoWrapper{
    min-width: 100% !important;
  }

  .sectionWrapper{
    padding-top:10px !important;
  }
  .gridWrapper{
    grid-gap:0px !important;
  }

  .gridWrapperStep5{

    .medyaDeneyimiRadioGroup{
      margin-top:30px;
    }

    .baglantiWrapper{
      flex-direction: column;

      .edits{
        width: 100%;
        margin-top:10px !important;
      }
      .buttonBaglantiEkle{
        margin-top:10px;
      }
    }

    .formBox3{
      display:none;

    }

    .formBox7{
      display:none;
    }

    .groupContacts2{
      margin-top:20px !important;
    }


    .contactWrapper .edits {
      font-size: 14px;
      margin-top: 10px !important;
      margin-bottom: 5px !important;
      align-self: self-start;
    }


  }

  .infoAltyapi{
    margin-bottom:20px;
  }

  .iletisimBilgileriCheckGroupPres{
    width: 100% !important;
    display: grid;
    grid-template-columns: 1px 1fr 50px 50px;
    grid-template-rows: 40px 60px 60px;
    grid-template-areas:
        ". formBox0 formBox1 formBox2"
        "formBox3 formBox4 formBox5 formBox6"
        "formBox7 formBox8 formBox9 formBox10";
    grid-gap: 5px;
    width: 100%;
    height: 100%;
 
    div{
      justify-content: center !important;
    }

    .formBox1 h5 {
      font-size: 12px;
    }

    .formBox2 h5 {
      font-size: 12px;
    }


   
  }

  .edits{
    font-size:15px;
  }
  
  .popup-content{
    width: 90%;
  }
  .modalForm{
    padding:20px;
  }

  .mainInfoWrapperKayitFormuRow {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .webSectionTitleExpert{
    text-align: center;
  }

  .subContentTitle{
    font-size:2.7rem !important; 
    text-align: center;
  }
  .subContentTitle2{
    font-size:3rem !important; 
  }

  .subContentParagraph{
    font-size: 1.3rem !important;
  }

  .ulProjeEkibi{
    li{
      text-align: center;
      margin-bottom:20px !important;
    }
  }

  .sectionCVWrapper{
    flex-direction: column;
    padding:10px !important;    
    align-items: center;

    .cvImageLayer{
      margin-right: 0px !important;
    }

    .cvImg{
      max-width: unset !important;
      max-height: unset !important;
      min-width: unset !important;
    }

    .cvContent{
      margin-top:30px;
    }

  }


  .subContentTitle3{
    text-align: center !important;
  }

  .subContentSectionWrapperSearch{
    padding:10px;
    margin-bottom:50px;

    .inputWrapperSearch{
      padding:0px;
      display:flex;
      justify-content: center;
    }

    .sectionSearchWrapper{
      flex-direction: column;
    }


    .edits-search{
      height: 65px !important;
      margin-right: 0px !important;
    }

    .searchContent{
      margin-top:30px;

      .lastExpertName{
        justify-content: center;
        text-align: center;
        width: 100% !important;
        font-size:1.5rem;
      }

      .liveInfo{
        text-align: center;
      }


      .lastExpertImg{
        max-width: 259px;
        max-height: 291px;
        margin-left:auto;
        margin-right: auto;
      }

      .lastExpertCard {
        display: flex;
        flex-direction: column;
        margin-bottom:30px;
        justify-content: center;
      }



    }

  }

  .expertWrapper{
    display:flex;
    flex-direction: column;

    .expertProfileImg{
      width:100%;
      height: auto;
    }

  .expertFilterTitle{
    line-height: 1.5rem !important;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

    .lastExpertName{
      font-size:1.6rem !important;
      margin-bottom:5px !important;
      text-align: center !important;
      justify-content: center !important;
    }
    .lastExpertTitle{
      font-size:1.6rem !important;
      min-height: unset !important;
      margin-top:0px !important;
      text-align: center !important;
      
    }

  }

  .expertSectionTitle{
    text-align: center;
  }
  .expertIntroUl li{
    display: flex;
    flex-direction: column;

    div{
      flex-direction: column;
    }
  }
  .expertContent {
    p{
      font-size:1.3rem;
    }

    ul{
      li{
        font-size:1.3rem;
        text-align: center;
      }
    }


  }

  .blogSectionWrapper{
    padding:10px;
    display:flex;
    flex-direction: column;

    .articleContentTop{
      justify-content: center;
      flex-direction: column;
      align-items: center;
      img{
        width:280px;
        height:106px;
        max-width: 300px;
      }

      .articleItemTitle{
        position:relative !important;
        font-size:2.7rem !important;
        top:unset !important;
        right:unset !important;
      }      

    }



    .articleItemContent{
      font-size:1.3rem !important;
      margin-top:10px !important;

      a{
        text-align: center;
      }

    }

  }

  .egitimGrid{
    display: flex !important;
    flex-direction: column;

    margin-bottom:30px !important;


    .egitimColText{
      font-size:1.2rem !important;
      width:100% !important;
      line-height: 1.5rem !important;
    }
  }

  .egitimTitle{
    text-align: center !important;
  }

  .subContentSectionFAQWrapper{
    margin-top:0px !important;
    padding:10px;
    padding-top:20px;


    .subContentTitleFAQ{
      font-size:2.7rem;
      text-align: center;
      line-height: 3rem;
      margin-bottom:10px;
    }

    .faq{
      position: relative;
      display: block;;
      padding-top:30px;

      .faq__question{
        max-width: 100%;
        position: relative;
      }

      .faq__question-button{
        width: 100%;
        font-size: 1.4rem;
        line-height: 1.4rem;        
      }      

      .faq__desc{
        font-size:1.2rem !important;
      }

    }

  }

  .expertProfileNew{

    .edits-login{
      height:65px;
    }
    .btnFilterSearch {
      height:65px !important;
    }

    .socialSelect {
      height:65px;

      div[class*=control]{
        height:60px;
      }

    }

    .expertWrapper{
      padding-top:10px;
    }
    .expertListSticky{
      position: relative;
      top:0px;
    }

    .expertContent{
      margin-top:140px;
    }

    .webSectionTitle{
      margin-bottom:40px;
      margin-top:40px;
    }

    .sectionTitle{
      font-size:1.7rem !important;
      text-align: center !important;      
    }

    .profileItem{
      display: flex;
      align-items: center !important;
    }

    .iletisimBilgileriCheckGroupPres{
      grid-template-columns: minmax(200px, 600px) 100px;
    }

  }
  .loginSection .loginSectionFooter {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
  .loginContentWrapper{
    padding:20px !important;
  }

  .sectionStepNew{
    padding:10px;
  }

  .sectionWrapperNew {

    .imageToolbarNew{
      flex-direction: column;
    }

    .buttonUploadNew{
      margin-bottom:20px;
    }


    .mainInfoWrapper{
      padding:0px;
    }

    .mainInfoItem{
      margin-right: 0px !important;
    }

    .gridWrapperNew{
      display:flex;
      flex-direction: column;
    }

    .gridWrapperNew3{
      display:flex;
      flex-direction: column;      
    }
    .gridWrapperNew4{
      display:flex;
      flex-direction: column;      
    }

  
    .iletisimBilgileriCheckGroupPres{
      width:100%;
      grid-template-columns:1px 1fr 95px ;
      grid-template-rows:40px 1fr 1fr;
      grid-template-areas:
      ". formBox0 formBox1 formBox2"
      "formBox3 formBox4 formBox5 formBox6"
      "formBox7 formBox8 formBox9 formBox10"
      "formBox11 formBox11 formBox12 formBox12";

      .edits-login{
        height:65px;
      }

    }

    .formBox0{
      .sectionTitle{
        font-size:1.4rem;
        text-align: left;
      }
    }

    .formBox1{
      .subtitle{
        font-size: 1.4rem;
      }
    }

    .formBox11{
      width:100%;
      padding-left:0px;
    }

  }

  .navbarsticky{
    padding-left: 10px !important;
    padding-right:10px !important;



  }    


  .navbarhome{
    .navbarhome-logo{
      width:152px !important;
      height:75px !important;
    }
  }

  .blogContentLogoImg{
    position: relative;
    width:280px !important;
    height:106px !important;
    margin-left: auto;
    margin-right: auto;
  }

  .expertsearchpage .newContentLayerWrapper {
    padding-left:10px !important;
    padding-right: 10px !important;
  }  
  .blogPageImg{
    width:300px;
    height: auto;
  }

  .videoGrid{
    grid-template-columns: repeat(1, 1fr) !important;
    grid-template-rows:400px;
    height: 100% !important;
    
  }

}
@media only screen and (min-width: 961px) and (max-width: 1199px) {

  #mainID{
    // padding-left:150px;
    // padding-right:150px;
    
  } 

  .homeWrapper{
    display: block;
    max-width: 100%;
  }

  .newSectionWrapper{
    display:block;
    width: 100%;
    height:100%;
    max-width:100%;
    padding-left:30px;
    padding-right:30px;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:100px;
  }  

  #navID{
    // padding-left:72px;
    // padding-right: 72px;
    max-width:100%;
    margin-left:auto;
    margin-right:auto;
    padding-left:30px;
    padding-right: 30px;
    margin-top:32px;

    .nav-menu-sub{
      font-size: 1rem !important;
    }    

    .bottomMenu{

      .menuitem{
        margin-left:10px;
      }



      li{
        a{
          font-size:1rem;
        }
      }
    }

  }


  .navbarsticky{
    margin-top:0px !important;
    // transition: all 0.5s ease;   
    .menuitem{
      margin-left:15px !important;
    }    
  }

  .logoContainer img {
    max-height: 138px;
    max-width: 279px;
  }    

  .navbarsticky .logoContainer img {
    max-height: 65px;
  }  

  .sliderID{
    position: relative;
    max-width:100%;
    margin-left:auto;
    margin-right:auto;
    padding-left:30px;
    padding-right: 30px;

  }

  .sliderWrapper{
    height:700px;
    background-size: 302px 200px;
    background-position: bottom 176px right 30px;
    padding-left:50px;
    padding-top:50px;

    .sliderQuote{
      min-width:300px;
      width:auto;
      font-size:3rem;
      line-height: 3rem;
      max-width: 650px;
    }

    .sliderText{

      &:first-of-type{
        margin-top:20px;
      }
    }

    .sliderAttachLeft{
      background-size: contain;
      background-repeat: no-repeat;
      width:45px;
      height: 36px;
      left:-20px;
    }
    .sliderAttachRight{
      background-size: contain;
      background-repeat: no-repeat;
      width:45px;
      height: 36px;
      bottom:-25px;      
    }

  }
  .filterSectionWrapperID{
    max-width:100%;
    margin-left:auto;
    margin-right:auto;
    background-size: 98%;
    padding-left:30px;
    padding-right:30px;    
    background-size: 135%;


  }  

  .mainWorksWrapper{
    max-width: 100%;
    padding-left:30px;
    padding-right:30px;    
    margin-left:auto;
    margin-right: auto;
    padding-top:122px;
    padding-bottom:122px;

    .mainWorksHeaderImg{
      max-width: 417px !important;
    }

    .mainWorksHeaderTitle{
      font-size: 2.3rem !important;
    }

    .workshop-content-wrapper{
      height: 480px !important;
    }


    .workshopWrapper{
      grid-gap:40px;

      .workshop-image{
        max-width: 461px !important;
        max-height: 462px !important;
      }


      .workshop-title-wrapper{
        min-height: 130px;
      }

      .workshop-title{
        font-size:2.1rem !important;
        line-height: 2.4rem !important;
        margin-bottom:0px;
      }

      .workshop-content{
        font-size:1.1rem !important;
      }



    }


  }

  #hakkindIDWrapper{
    max-width:100%;
    margin-left:auto;
    margin-right:auto;

  }
  #makalelerIDWrapper{
    max-width:100%;
    // margin-left:auto;
    // margin-right:auto;

    .articlesSectionWrapper{
      padding-left:52px;
      padding-right: 52px;
    }


    .blogContentLogoImg{
      max-height:137px;
    }

    .blogContentBlogImg{
      object-fit: fill;
      width: 100%;
      height: 100%;
    }

    .articleItemTitle{
      font-size:2.2rem;
    }

    .articleItemContent{
      font-size: 1.2rem;
    }


  }
  #egitimlerIDWrapper{
    max-width:100%;
    margin-left:auto;
    margin-right:auto;

  }

  /* Home Page*/
  .filterSectionWrapper{
    min-height: 330px !important;
    // padding-bottom:60px;
  }

 


  .aboutlayerSectionWrapper{

    .webSectionTitle{
      margin-top:0px !important;
    }

  }

  .newFooter{
    height:505px;
    padding-top: 49px;
    padding-left: 37px;
    padding-bottom: 0px;
    padding-right: 37px;    

    .footerbar-logo{
      max-height: 165px;
    }

    .foot2 {
      p{
        font-size:1.2rem;

        &:last-child{
          font-size:1.2rem;
        }

      }
      
    }

    .foot3{
      .socialList{
        li{
          font-size:1.2rem !important;
        }
        
      }
    }

    .foot4 {
      .faqWrapper{
        h5{
          font-size: 1.2rem;
        }
      }
    }  

  }  


  /***
  Other Pages
  ***/

  .webSectionTitleExpert{
    font-size:2rem;
  }

  .subContentSectionWrapperSearch{
    padding-top: 53px;
    padding-left: 0px;
    padding-bottom: 53px;
    padding-right: 0px; 
    
    .sectionSearchWrapper{
      justify-content: space-around;
    }
    
  }

  .edits-search{
    height:45px !important;
    width:100% !important;
    // max-width:300px !important;
    font-size:1.2rem !important;
  }

  .select-search {
    height: 45px !important;
    border-radius: 0px;
    border: 1px solid #c2c1bd;
    padding-left: 15px;
    font-size: 1rem !important;
    margin-bottom: 20px;
    width: 300px !important;
    font-family: "Baskerville BT", serif;
    outline-color: #3f5642;
    margin-right: 30px;
  }  

  .btnSearch{
    font-size: 1.4rem !important;
    font-weight: normal;
    height: 45px !important;
    width: 213px !important;    
  }


  .lastExpertsContentWrapper{
    display:grid;
    // grid-template-columns: repeat(5,auto);
    // grid-template-columns: repeat(auto-fill, minmax(min(100px,200px), 1fr));
    grid-template-columns: repeat(auto-fill, minmax(200px, 200px)) !important;
    grid-gap: 28px;
    padding-top:30px;
    width: 100%;
    max-width: 900px;
    margin-left:auto;
    margin-right:auto;
  
  
  
  
    .lastExpertCard{
      position: relative;
      &:hover{
        cursor:pointer !important;
      }
  
      .lastExpertImg{
        margin-bottom:5px;
        width: 100%;
        height: 100%;
        max-height: 200px;
        border:8px solid #84ac87;
      }
  
      .lastExpertName{
        display: flex;
        // justify-content: center;
        align-items: center;      
        color:#000;
        text-shadow: 0 0 #000;
        font-size:1.3rem;
        line-height:1.3rem;
        font-family: 'Baskerville BT',serif;
        margin-top:10px;
        text-align:left;
        width:100%;
        margin-bottom:0px;
  
        &.selected{
          color:#84ac87 !important;
          text-shadow: 0 0 #84ac87;
        }
              
      }
  
      .liveInfo{
        font-family: 'Baskerville BT',serif;   
        font-size:1.2rem;  
        color:#000;
        font-style: italic; 
        text-shadow: 0 0 #000;
  
        &.selected{
          color:#84ac87 !important;
          text-shadow: 0 0 #84ac87;
        }
  
      }
  
      .lastExpertTitle{
        color:#586a80;
        font-family: 'Baskerville BT',serif;
        font-weight: normal;
        font-style: italic;
        font-size: 16px;
        min-height: 50px;
        text-align:center;
      }
  
  
      .expertButtonWrapper{
        display:flex;
        justify-content: center;
      }
  
      .lastExpertDetailBtn{
        // margin-top:20px;
        font-family: 'Baskerville BT',serif;
        padding-top: 6.5px;
        padding-bottom: 6.5px;      
        border-radius: 0;
        background:#384a5f;
  
        &:hover{
          background:#586a80 !important;
          color:#fff;
          transition: all 0.3s ease-out;
          cursor:pointer;
        }
  
      }
      
    }
  
  }
  


  .expertWrapper{
    display: grid;
    border-top:4px solid #84ac87;
    grid-template-columns: 0.5fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 30px;
    width: 100%;
    height: 100%;
    font-size: 1.3rem;
    padding-top:85px;   
    margin-top:30px;
  }
  
  .expertpublishs{
    color:#000;
  
    &:hover{
      color:#506c53;
    }
  }
  
  .expertContent{
    p{
      color:#000;
      font-size:1.4rem;
      font-family: 'Baskerville BT',serif;
      text-shadow: 0 0 #000;      
      word-wrap: break-word;
    }
  
    ul{
      list-style: none;
      margin-left:2px;
  
      li{
        color:#000;
        text-shadow: 0 0 #000;      
        font-size:1.4rem;
        font-family: 'Baskerville BT',serif;   

      }
    }
    
  }

  .socialMedyaInfo{
    word-break: break-all; 
  }

  .expertpublishs{
    word-break: break-all; 
  }
  
  .expertListSticky{
    position: sticky;
    top: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  
    .editsfilter{
      margin-top:0px;
    }
  
    .editfiltericon {
      position: absolute;
      left: 14px;
      top: 8px;
    }
  
    .buttonwrapper{
      width: auto !important;
  
      .btnFilterexpert{
        &:hover{
          cursor:pointer;
          background: #FF004B;
        }
      }
    }
  
    .react-dropdown-select {
      position: relative;
      margin-top: 0px;
      height: 40px;
      border-radius: 5px;
      border: 1px solid #3f5642;
      outline-color: #000 !important;
      max-width: 300px;
      width: 100%;
      font-weight: 300;
      font-size: 15px;
      font-family: 'Baskerville BT',serif;
      background:#fff;
  
      
    }
  
    .react-dropdown-select-input{
      position:absolute;
      top:5px;
      width: 100%;
  
      ::placeholder{
        font-size:15px;
      }
    }
  
    div[class*="ContentComponent"]{
      padding-left:5px;
      padding-right: 5px;
    }
          
  
  }
  
  .expertSectionTitle{
    font-family: "Baskerville BT",serif;
    font-size:1.8rem;
    line-height: 2rem;
    color:#000000;
    font-weight: bold;
    
  
  }
  
    .ulexpertList{
      list-style: none;
      font-size:1.4rem;
  
      li{
        margin-bottom: 10px;
        color:#000;
        font-weight: 500;
        font-size: 17px;
        font-family: 'Baskerville BT',serif;
        line-height: 17px;     
        text-align: center; 
  
        &.expertFilterTitle{
          color:#000;
          font-weight: 500;
          font-family: 'Baskerville BT',serif;
          line-height: 2.2rem;    
          font-size: 2.2rem;  
  
          .lastExpertName{
            margin-top:20px;
            color:#000;
            font-size:2rem;
            text-shadow: 0 0 #000;    
            font-family: 'Baskerville BT',serif;
            font-weight: lighter;
            margin-bottom:5px;
            text-align:left;
            display: flex;
            justify-content: flex-start;
            align-items: center;   
            
            
          }
          .lastExpertTitle{
            color:#000;
            margin-top:10px; 
            font-family: 'Baskerville BT',serif;
            font-weight: normal;
            font-style: italic;
            font-size:1.8rem;
            min-height: 50px;
            text-align:left;
          }
  
          .lastExpertContactTitle{
            color:#000;
            font-size:1.8rem;
            text-shadow: 0 0 #000;    
            font-family: 'Baskerville BT',serif;
            font-weight: bold;          
            margin-bottom:5px;
            text-align:left;
            display: flex;
            justify-content: flex-start;
            align-items: center;      
          }        
      
  
        }
  
      }
  
  
      .expertFilterContent{
        .inputWrapper.web{
          div{
            &:first-of-type{
              width:100%;
            }
          }
        }
      }
  
  
    }  

    .expertProfileImg{
      box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.20);
      margin-bottom:25px;
      width: 300px;
      height: 318px;
      border: 4px solid #84ac87;
    
    }
    .expertLoading{
      display:flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    
      p{
        width: 100%;
        text-align: center;
        font-size: 1.3rem;
        font-weight: 500;
    
      }
    }
    
    .expertSocialMedyaUl{
      list-style: none;
      margin-left:0px !important;
    
      .socialMedyaInfo{
        margin-left:0px;
        color:#000;
      }
    
      .socialLine{
        margin-top:0px;
        margin-bottom:0px;
      }
    
      li{
        margin-bottom:0px;
      }
    }
    
    .expertIntroUl{
      list-style: none;
      margin-left:0px !important;
      font-size: 1.4rem;
      font-family: 'Baskerville BT',serif;
    
    
      li{
        display:grid;
        grid-template-columns: 250px 1fr;
        margin-top:10px;
        font-size: 1.4rem;
        text-shadow: 0 0 #000;      
        font-family: 'Baskerville BT',serif;
        color: #000;
    
        div{
          display:flex;
          align-items: center;
          font-size: 1.4rem;
          text-shadow: 0 0 #000;      
          font-family: 'Baskerville BT',serif;
          color: #000;
    
          span{
            font-size: 1.4rem;
            text-shadow: 0 0 #000;      
            font-family: 'Baskerville BT',serif;
            color: #000;
          }
        }
    
        strong{
          margin-right:10px;
          color: #000;;
        }
    
        label{
          color: #000;
          font-size: 1.4rem;
          text-shadow: 0 0 #000;      
          font-family: 'Baskerville BT',serif;
          margin-bottom:0px;      
        }
    
      }
    }


    .sectionCVWrapper{
      padding-left:30px !important;
      padding-right: 30px !important;

        .cvImg{
          max-width: 240px;
          max-height: 289px;
          min-width: 240px;          
        }

    }

    .blogSectionWrapper{
      grid-template-columns: 1fr; 
    }

    .egitimGrid{
      margin-bottom:0px !important;
    }

    .egitimDetailWrapper{
      padding:30px;
    }

    .subContentTitle{
      font-size:3rem;
    }

    .farkindalikGrid{
      grid-template-columns: repeat(2, 1fr) !important;
    }

    .subContentTitleFAQ{
      font-size: 3rem !important;
    }

    .sectionNavBar{
      grid-template-columns: repeat(auto-fit, minmax(100px, 110px));    
    }
    .nav-links{
      font-size: 1.3rem !important;
    }    

  /***
  End of Other Pages
  ***/




  /***

    SignUp Page

  ***/  

  .navbar {
    grid-template-columns: repeat(auto-fit, minmax(100px, 170px)); 
    grid-gap: 20px;   
  }

  .headerContentWrapper{

    .webSectionTitle{
      font-size:28px;
      margin-top:20px;
    }
  }

  .mainSection{
    padding-left:20px;
    padding-right:20px;


    .sectionWrapper{
      max-width: 100% !important;
      padding-top:10px !important;
      // height: calc(100vh - 320px);

      .sectionTitle{
        font-size:16px;
      }

      .gridWrapper{
        grid-gap:20px;

        
        

        .medyaDeneyimiRadioGroup{
          margin-bottom:20px;
        }
        .iletisimBilgileriCheckGroupPres{
          grid-template-rows: 40px 40px 40px;

          .edits{
            height: 35px;
          }

        }

        .contactWrapper {
          .edits{
            height: 35px;
          }
        }

        .infoAltyapi{
          font-size:12px;
          line-height: 19px;
        }

        .altyapiSub{
          font-size:12px;
          line-height: 19px;
          margin-bottom:5px;
        }

        .margin-top-x{
          margin-top:10px;
        }


      }
      .gridWrapperStep5{

        div{
          &:nth-of-type(2){
            max-width: 560px;
          }
        }
      }

      .componentWrapper{
        width: 100%;
        

        .gorus-konular-step2{
          .ulYayinReferanslar{
            height: 180px;
          }
        }

        .contentInfoWrapper{
          max-height: auto;
        }

        .gorus-konular{
          min-width: 100%;
          height: 100% !important;
        }

        .ulYayinReferanslar{
          overflow:hidden;
          overflow-y: auto;
          height: 200px;
          margin:0px;
        }

        .step4GorusKonular{
          height: 210px !important;
        }

        .step5SosyalMedya{
          max-width: 360px !important;
        }

        .ulSosyalHesaplar{
          .socialLine {
            a{
              width: 25px !important;
              height: 25px !important;
            }
          }
        }

      }

    }

    .inputWrapper{
      .edits{
        margin-top:5px;
        margin-bottom:5px;
      }
    }
    .memos{
      height: 230px;
    }

    .ant-upload-select {
      width:120px !important;
      height: 120px !important;
      margin:0px !important;
    }
    .ant-upload-list-picture-card-container {
      width:120px !important;
      height: 120px !important;      
    }    

    .ant-upload-list-item{
      width:120px !important;
      height: 120px !important;      

    }

    .imageToolbar{
      margin-bottom:0px;
    }    

    .buttonUpload {
      top:50px;
    }

  }

  .sectionNavbar {
    grid-template-columns: repeat(auto-fit, minmax(100px, 120px));
  }


  footer{
    height: 65px;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    position: relative;
    bottom: 10px;
    padding-left: 3%;
    padding-right: 3%;
    justify-content: space-between;
    margin-top: 50px;
    padding-bottom: 40px;  
  }

}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {

  
  #mainID{
    // padding-left:150px;
    // padding-right:150px;
    
  } 

  .homeWrapper{
    display: block;
    max-width: 100%;
  }

  .newSectionWrapper{
    display:block;
    width: 100%;
    height:100%;
    max-width:100%;
    padding-left:30px;
    padding-right:30px;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:100px;
  }  

  #navID{
    // padding-left:72px;
    // padding-right: 72px;
    max-width:100%;
    margin-left:auto;
    margin-right:auto;
    padding-left:30px;
    padding-right: 30px;
    margin-top:32px;

    .bottomMenu{

      .menuitem{
        margin-left:24px;
      }

      li{
        a{
          font-size:1.2rem;
        }
      }
    }

  }


  .navbarsticky{
    margin-top:0px !important;
    // transition: all 0.5s ease;   
    .menuitem{
      margin-left:24px !important;
    }    
  }

  .logoContainer img {
    max-height: 138px;
    max-width: 279px;
  }    

  .navbarsticky .logoContainer img {
    max-height: 65px;
  }  

  .sliderID{
    position: relative;
    max-width:100%;
    margin-left:auto;
    margin-right:auto;
    padding-left:30px;
    padding-right: 30px;

  }

  .sliderWrapper{
    height:700px;
    background-size: 502px 400px;
    background-position: bottom 76px right 30px;
    padding-left:70px;
    padding-top:70px;

    .sliderQuote{
      min-width:300px;
      width:auto;
      font-size:3rem;
      line-height: 3rem;
      max-width: 650px;
    }

    .sliderText{

      &:first-of-type{
        margin-top:20px;
      }
    }

    .sliderAttachLeft{
      background-size: contain;
      background-repeat: no-repeat;
      width:45px;
      height: 36px;
      left:-50px;
    }
    .sliderAttachRight{
      background-size: contain;
      background-repeat: no-repeat;
      width:45px;
      height: 36px;
      bottom:-25px;      
    }

  }
  .filterSectionWrapperID{
    max-width:100%;
    margin-left:auto;
    margin-right:auto;
    background-size: 98%;
    padding-left:30px;
    padding-right:30px;    


  }  

  .mainWorksWrapper{
    max-width: 100%;
    padding-left:30px;
    padding-right:30px;    
    margin-left:auto;
    margin-right: auto;
    padding-top:122px;
    padding-bottom:122px;

    .mainWorksHeaderImg{
      max-width: 417px !important;
    }

    .mainWorksHeaderTitle{
      font-size: 2.3rem !important;
    }

    .workshopWrapper{
      grid-gap:40px;

      .workshop-image{
        max-width: 461px !important;
        max-height: 462px !important;
      }


      .workshop-title-wrapper{
        min-height: 130px;
      }

      .workshop-title{
        font-size:2.1rem !important;
        line-height: 2.4rem !important;
        margin-bottom:0px;
      }

      .workshop-content{
        font-size:1.1rem !important;
      }


    }


  }

  #hakkindIDWrapper{
    max-width:100%;
    margin-left:auto;
    margin-right:auto;

  }
  #makalelerIDWrapper{
    max-width:100%;
    // margin-left:auto;
    // margin-right:auto;

    .articlesSectionWrapper{
      padding-left:52px;
      padding-right: 52px;
    }


    .blogContentLogoImg{
      max-height:187px;
    }

    .blogContentBlogImg{
      object-fit: fill;
      width: 100%;
      height: 100%;
    }

    .articleItemTitle{
      font-size:3.2rem;
    }

  }
  #egitimlerIDWrapper{
    max-width:100%;
    margin-left:auto;
    margin-right:auto;

  }

  /* Home Page*/
  .filterSectionWrapper{
    min-height: 330px !important;
    // padding-bottom:60px;
  }

 


  .aboutlayerSectionWrapper{

    .webSectionTitle{
      margin-top:0px !important;
    }

  }

  .newFooter{
    height:565px;
    padding-top: 49px;
    padding-left: 37px;
    padding-bottom: 0px;
    padding-right: 37px;    

    .footerbar-logo{
      max-height: 215px;
    }

    .foot2 {
      p{
        font-size:1.4rem;

        &:last-child{
          font-size:1.4rem;
        }

      }
      
    }

    .foot3{
      .socialList{
        li{
          font-size:1.4rem !important;
        }
        
      }
    }

    .foot4 {
      .faqWrapper{
        h5{
          font-size: 1.4rem;
        }
      }
    }  

  }  




/***
  Other Pages
  ***/

  .webSectionTitleExpert{
    font-size:2rem;
  }

  .subContentSectionWrapperSearch{
    padding-top: 53px;
    padding-left: 0px;
    padding-bottom: 53px;
    padding-right: 0px; 
    
    .sectionSearchWrapper{
      justify-content: space-around;
    }
    
  }

  .edits-search{
    height:45px !important;
    width:100% !important;
    // max-width:300px !important;
    font-size:1.2rem !important;
  }

  .select-search {
    height: 45px !important;
    border-radius: 0px;
    border: 1px solid #c2c1bd;
    padding-left: 15px;
    font-size: 1rem !important;
    margin-bottom: 20px;
    width: 300px !important;
    font-family: "Baskerville BT", serif;
    outline-color: #3f5642;
    margin-right: 30px;
  }  

  .btnSearch{
    font-size: 1.4rem !important;
    font-weight: normal;
    height: 45px !important;
    width: 213px !important;    
  }


  .lastExpertsContentWrapper{
    display:grid;
    // grid-template-columns: repeat(5,auto);
    // grid-template-columns: repeat(auto-fill, minmax(min(100px,200px), 1fr));
    // grid-template-columns: repeat(auto-fill, minmax(200px, 200px)) !important;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important;
    grid-gap: 28px;
    padding-top:30px;
    width: 100%;
    // max-width: 900px;
  
  
  
  
    .lastExpertCard{
      position: relative;
      &:hover{
        cursor:pointer !important;
      }
  
      .lastExpertImg{
        margin-bottom:5px;
        width: 100%;
        height: 100%;
        // max-height: 200px;
        border:8px solid #84ac87;
      }
  
      .lastExpertName{
        display: flex;
        // justify-content: center;
        align-items: center;      
        color:#000;
        text-shadow: 0 0 #000;
        font-size:1.3rem;
        line-height:1.3rem;
        font-family: 'Baskerville BT',serif;
        margin-top:10px;
        text-align:left;
        width:100%;
        margin-bottom:0px;
  
        &.selected{
          color:#84ac87 !important;
          text-shadow: 0 0 #84ac87;
        }
              
      }
  
      .liveInfo{
        font-family: 'Baskerville BT',serif;   
        font-size:1.2rem;  
        color:#000;
        font-style: italic; 
        text-shadow: 0 0 #000;
  
        &.selected{
          color:#84ac87 !important;
          text-shadow: 0 0 #84ac87;
        }
  
      }
  
      .lastExpertTitle{
        color:#586a80;
        font-family: 'Baskerville BT',serif;
        font-weight: normal;
        font-style: italic;
        font-size: 16px;
        min-height: 50px;
        text-align:center;
      }
  
  
      .expertButtonWrapper{
        display:flex;
        justify-content: center;
      }
  
      .lastExpertDetailBtn{
        // margin-top:20px;
        font-family: 'Baskerville BT',serif;
        padding-top: 6.5px;
        padding-bottom: 6.5px;      
        border-radius: 0;
        background:#384a5f;
  
        &:hover{
          background:#586a80 !important;
          color:#fff;
          transition: all 0.3s ease-out;
          cursor:pointer;
        }
  
      }
      
    }
  
  }
  


  .expertWrapper{
    display: grid;
    border-top:4px solid #84ac87;
    grid-template-columns: 0.5fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 30px;
    width: 100%;
    height: 100%;
    font-size: 1.3rem;
    padding-top:85px;   
    margin-top:30px;
  }
  
  .expertpublishs{
    color:#000;
  
    &:hover{
      color:#506c53;
    }
  }
  
  .expertContent{
    p{
      color:#000;
      font-size:1.4rem;
      font-family: 'Baskerville BT',serif;
      text-shadow: 0 0 #000;      
      word-wrap: break-word;
    }
  
    ul{
      list-style: none;
      margin-left:2px;
  
      li{
        color:#000;
        text-shadow: 0 0 #000;      
        font-size:1.4rem;
        font-family: 'Baskerville BT',serif;   

      }
    }
    
  }

  .socialMedyaInfo{
    word-break: break-all; 
  }

  .expertpublishs{
    word-break: break-all; 
  }
  
  .expertListSticky{
    position: sticky;
    top: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  
    .editsfilter{
      margin-top:0px;
    }
  
    .editfiltericon {
      position: absolute;
      left: 14px;
      top: 8px;
    }
  
    .buttonwrapper{
      width: auto !important;
  
      .btnFilterexpert{
        &:hover{
          cursor:pointer;
          background: #FF004B;
        }
      }
    }
  
    .react-dropdown-select {
      position: relative;
      margin-top: 0px;
      height: 40px;
      border-radius: 5px;
      border: 1px solid #3f5642;
      outline-color: #000 !important;
      max-width: 300px;
      width: 100%;
      font-weight: 300;
      font-size: 15px;
      font-family: 'Baskerville BT',serif;
      background:#fff;
  
      
    }
  
    .react-dropdown-select-input{
      position:absolute;
      top:5px;
      width: 100%;
  
      ::placeholder{
        font-size:15px;
      }
    }
  
    div[class*="ContentComponent"]{
      padding-left:5px;
      padding-right: 5px;
    }
          
  
  }
  
  .expertSectionTitle{
    font-family: "Baskerville BT",serif;
    font-size:1.8rem;
    line-height: 2rem;
    color:#000000;
    font-weight: bold;
    
  
  }
  
    .ulexpertList{
      list-style: none;
      font-size:1.4rem;
  
      li{
        margin-bottom: 10px;
        color:#000;
        font-weight: 500;
        font-size: 17px;
        font-family: 'Baskerville BT',serif;
        line-height: 17px;     
        text-align: center; 
  
        &.expertFilterTitle{
          color:#000;
          font-weight: 500;
          font-family: 'Baskerville BT',serif;
          line-height: 2.2rem;    
          font-size: 2.2rem;  
  
          .lastExpertName{
            margin-top:20px;
            color:#000;
            font-size:2rem;
            text-shadow: 0 0 #000;    
            font-family: 'Baskerville BT',serif;
            font-weight: lighter;
            margin-bottom:5px;
            text-align:left;
            display: flex;
            justify-content: flex-start;
            align-items: center;   
            
            
          }
          .lastExpertTitle{
            color:#000;
            margin-top:10px; 
            font-family: 'Baskerville BT',serif;
            font-weight: normal;
            font-style: italic;
            font-size:1.8rem;
            min-height: 50px;
            text-align:left;
          }
  
          .lastExpertContactTitle{
            color:#000;
            font-size:1.8rem;
            text-shadow: 0 0 #000;    
            font-family: 'Baskerville BT',serif;
            font-weight: bold;          
            margin-bottom:5px;
            text-align:left;
            display: flex;
            justify-content: flex-start;
            align-items: center;      
          }        
      
  
        }
  
      }
  
  
      .expertFilterContent{
        .inputWrapper.web{
          div{
            &:first-of-type{
              width:100%;
            }
          }
        }
      }
  
  
    }  

    .expertProfileImg{
      box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.20);
      margin-bottom:25px;
      width: 360px;
      height: 398px;
      border: 4px solid #84ac87;
    
    }
    .expertLoading{
      display:flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    
      p{
        width: 100%;
        text-align: center;
        font-size: 1.3rem;
        font-weight: 500;
    
      }
    }
    
    .expertSocialMedyaUl{
      list-style: none;
      margin-left:0px !important;
    
      .socialMedyaInfo{
        margin-left:0px;
        color:#000;
      }
    
      .socialLine{
        margin-top:0px;
        margin-bottom:0px;
      }
    
      li{
        margin-bottom:0px;
      }
    }
    
    .expertIntroUl{
      list-style: none;
      margin-left:0px !important;
      font-size: 1.4rem;
      font-family: 'Baskerville BT',serif;
    
    
      li{
        display:grid;
        grid-template-columns: 250px 1fr;
        margin-top:10px;
        font-size: 1.4rem;
        text-shadow: 0 0 #000;      
        font-family: 'Baskerville BT',serif;
        color: #000;
    
        div{
          display:flex;
          align-items: center;
          font-size: 1.4rem;
          text-shadow: 0 0 #000;      
          font-family: 'Baskerville BT',serif;
          color: #000;
    
          span{
            font-size: 1.4rem;
            text-shadow: 0 0 #000;      
            font-family: 'Baskerville BT',serif;
            color: #000;
          }
        }
    
        strong{
          margin-right:10px;
          color: #000;;
        }
    
        label{
          color: #000;
          font-size: 1.4rem;
          text-shadow: 0 0 #000;      
          font-family: 'Baskerville BT',serif;
          margin-bottom:0px;      
        }
    
      }
    }


    .sectionCVWrapper{
      padding-left:30px !important;
      padding-right: 30px !important;

        .cvImg{
          max-width: 240px;
          max-height: 289px;
          min-width: 240px;          
        }

    }

    .blogSectionWrapper{
      grid-template-columns: 1fr; 
    }

    .egitimGrid{
      margin-bottom:0px !important;
    }

    .egitimDetailWrapper{
      padding:30px;
    }

    .subContentTitle{
      font-size:3rem;
    }

    .farkindalikGrid{
      grid-template-columns: repeat(2, 1fr) !important;
    }

    .subContentTitleFAQ{
      font-size: 3rem !important;
    }

    .sectionNavBar{
      grid-template-columns: repeat(auto-fit, minmax(100px, 110px));    
    }
    .nav-links{
      font-size: 1.3rem !important;
    }    

  /***
  End of Other Pages
  ***/











  /***

    SignUp Page

  ***/  

  
  .navbar {
    grid-template-columns: repeat(auto-fit, minmax(100px, 170px)); 
    grid-gap: 20px;   
  }

  .headerContentWrapper{

    .webSectionTitle{
      font-size:28px;
      margin-top:20px;
    }
  }

  .mainSection{
    padding-left:20px;
    padding-right:20px;


    .sectionWrapper{
      max-width: 100% !important;
      padding-top:10px !important;
      height: calc(100vh - 320px);

      .sectionTitle{
        font-size:16px;
      }

      .gridWrapper{
        grid-gap:20px;

        
        

        .medyaDeneyimiRadioGroup{
          margin-bottom:20px;
        }
        .iletisimBilgileriCheckGroupPres{
          grid-template-rows: 40px 40px 40px;

          .edits{
            height: 35px;
          }

        }

        .contactWrapper {
          .edits{
            height: 35px;
          }
        }

        .infoAltyapi{
          font-size:12px;
          line-height: 19px;
        }

        .altyapiSub{
          font-size:12px;
          line-height: 19px;
          margin-bottom:5px;
        }

        .margin-top-x{
          margin-top:10px;
        }


      }
      .gridWrapperStep5{

        div{
          &:nth-of-type(2){
            max-width: 560px;
          }
        }
      }

      .componentWrapper{
        width: 100%;
        

        .gorus-konular-step2{
          .ulYayinReferanslar{
            height: 180px;
          }
        }

        .contentInfoWrapper{
          max-height: auto;
        }

        .gorus-konular{
          min-width: 100%;
          height: 100% !important;
        }

        .ulYayinReferanslar{
          overflow:hidden;
          overflow-y: auto;
          height: 200px;
          margin:0px;
        }

        .step4GorusKonular{
          height: 210px !important;
        }

        .step5SosyalMedya{
          max-width: 360px !important;
        }

        .ulSosyalHesaplar{
          .socialLine {
            a{
              width: 25px !important;
              height: 25px !important;
            }
          }
        }

      }

    }

    .inputWrapper{
      .edits{
        margin-top:5px;
        margin-bottom:5px;
      }
    }
    .memos{
      height: 230px;
    }

    .ant-upload-select {
      width:120px !important;
      height: 120px !important;
      margin:0px !important;
    }
    .ant-upload-list-picture-card-container {
      width:120px !important;
      height: 120px !important;      
    }    

    .ant-upload-list-item{
      width:120px !important;
      height: 120px !important;      

    }

    .imageToolbar{
      margin-bottom:0px;
    }    

    .buttonUpload {
      top:50px;
    }

  }

  .sectionNavbar {
    grid-template-columns: repeat(auto-fit, minmax(100px, 180px));
  }


  footer{
    height: 65px;
    width: 100%; 
    display: flex;
    box-sizing: border-box;
    position:fixed;
    bottom:10px;
    padding-left:3%;
    padding-right:3%;     
    justify-content: space-between;    
  }

}
@media only screen and (min-width: 1366px) and (max-width: 1599px){

  #mainID{
    // padding-left:150px;
    // padding-right:150px;
    
  } 

  .homeWrapper{
    display: block;
    max-width: 100%;
  }

  .newSectionWrapper{
    display:block;
    width: 100%;
    height:100%;
    max-width:100%;
    padding-left:30px;
    padding-right:30px;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:100px;
  }  

  #navID{
    // padding-left:72px;
    // padding-right: 72px;
    max-width:100%;
    margin-left:auto;
    margin-right:auto;
    padding-left:30px;
    padding-right: 30px;
    margin-top:32px;
  }


  .navbarsticky{
    margin-top:0px !important;
    // transition: all 0.5s ease;   
  }

  .logoContainer img {
    max-height: 198px;
  }    

  .navbarsticky .logoContainer img {
    max-height: 65px;
  }  

  .sliderID{
    position: relative;
    max-width:100%;
    margin-left:auto;
    margin-right:auto;
    padding-left:30px;
    padding-right: 30px;

  }

  .sliderWrapper{
    height:700px;
    background-size: 725px 513px;
    background-position: bottom 6px right 30px;
    padding-left:70px;
    padding-top:70px;

    .sliderQuote{
      min-width:300px;
      width:auto;
      font-size:3rem;
      line-height: 3rem;
      max-width: 650px;
    }

    .sliderText{

      &:first-of-type{
        margin-top:20px;
      }
    }

    .sliderAttachLeft{
      background-size: contain;
      background-repeat: no-repeat;
      width:45px;
      height: 36px;
      left:-50px;
    }
    .sliderAttachRight{
      background-size: contain;
      background-repeat: no-repeat;
      width:45px;
      height: 36px;
      bottom:-25px;      
    }

  }
  .filterSectionWrapperID{
    max-width:100%;
    margin-left:auto;
    margin-right:auto;
    background-size: 98%;
    padding-left:30px;
    padding-right:30px;    


  }  

  .mainWorksWrapper{
    max-width: 100%;
    padding-left:30px;
    padding-right:30px;    
    margin-left:auto;
    margin-right: auto;
    padding-top:122px;
    padding-bottom:122px;

    .mainWorksHeaderImg{
      max-width: 417px !important;
    }

    .mainWorksHeaderTitle{
      font-size: 2.3rem !important;
    }

    .workshopWrapper{
      grid-gap:40px;

      .workshop-image{
        max-width: 461px !important;
        max-height: 462px !important;
      }


      .workshop-title-wrapper{
        min-height: 130px;
      }

      .workshop-title{
        font-size:2.1rem !important;
        line-height: 2.4rem !important;
        margin-bottom:0px;
      }

      .workshop-content{
        font-size:1.25rem !important;
      }


    }


  }

  #hakkindIDWrapper{
    max-width:100%;
    margin-left:auto;
    margin-right:auto;

  }
  #makalelerIDWrapper{
    max-width:100%;
    // margin-left:auto;
    // margin-right:auto;

    .articlesSectionWrapper{
      padding-left:52px;
      padding-right: 52px;
    }


    .blogContentLogoImg{
      max-height:227px;
    }

    .blogContentBlogImg{
      object-fit: fill;
      width: 100%;
      height: 100%;
    }

    .articleItemTitle{
      font-size:3.2rem;
    }

  }
  #egitimlerIDWrapper{
    max-width:100%;
    margin-left:auto;
    margin-right:auto;

  }

  /* Home Page*/
  .filterSectionWrapper{
    min-height: 330px !important;
    // padding-bottom:60px;
  }

 


  .aboutlayerSectionWrapper{

    .webSectionTitle{
      margin-top:0px !important;
    }

  }

  .newFooter{
    height:615px;
    padding-top: 69px;
    padding-left: 57px;
    padding-bottom: 0px;
    padding-right: 57px;    

    .footerbar-logo{
      max-height: 215px;
    }

    .foot2 {
      p{
        font-size:1.7rem;

        &:last-child{
          font-size:1.7rem;
        }

      }
      
    }

    .foot4 {
      .faqWrapper{
        h5{
          font-size: 1.7rem;
        }
      }
    }  

  }  

  .sectionNavbar {
    grid-template-columns: repeat(auto-fit, minmax(100px, 180px));
  }










/***
  Other Pages
  ***/

  .webSectionTitleExpert{
    font-size:2rem;
  }

  .subContentSectionWrapperSearch{
    padding-top: 53px;
    padding-left: 0px;
    padding-bottom: 53px;
    padding-right: 0px; 
    
    .sectionSearchWrapper{
      justify-content: space-around;
    }
    
  }

  .edits-search{
    height:45px !important;
    width:100% !important;
    // max-width:300px !important;
    font-size:1.2rem !important;
  }

  .select-search {
    height: 45px !important;
    border-radius: 0px;
    border: 1px solid #c2c1bd;
    padding-left: 15px;
    font-size: 1rem !important;
    margin-bottom: 20px;
    width: 300px !important;
    font-family: "Baskerville BT", serif;
    outline-color: #3f5642;
    margin-right: 30px;
  }  

  .btnSearch{
    font-size: 1.4rem !important;
    font-weight: normal;
    height: 45px !important;
    width: 213px !important;    
  }


  .lastExpertsContentWrapper{
    display:grid;
    // grid-template-columns: repeat(5,auto);
    // grid-template-columns: repeat(auto-fill, minmax(min(100px,200px), 1fr));
    // grid-template-columns: repeat(auto-fill, minmax(200px, 200px)) !important;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important;
    grid-gap: 28px;
    padding-top:30px;
    width: 100%;
    // max-width: 900px;
    
  
  
  
  
    .lastExpertCard{
      position: relative;
      &:hover{
        cursor:pointer !important;
      }
  
      .lastExpertImg{
        margin-bottom:5px;
        width: 100%;
        height: 100%;
        // max-height: 200px;
        border:8px solid #84ac87;
      }
  
      .lastExpertName{
        display: flex;
        // justify-content: center;
        align-items: center;      
        color:#000;
        text-shadow: 0 0 #000;
        font-size:1.3rem;
        line-height:1.3rem;
        font-family: 'Baskerville BT',serif;
        margin-top:10px;
        text-align:left;
        width:100%;
        margin-bottom:0px;
  
        &.selected{
          color:#84ac87 !important;
          text-shadow: 0 0 #84ac87;
        }
              
      }
  
      .liveInfo{
        font-family: 'Baskerville BT',serif;   
        font-size:1.2rem;  
        color:#000;
        font-style: italic; 
        text-shadow: 0 0 #000;
  
        &.selected{
          color:#84ac87 !important;
          text-shadow: 0 0 #84ac87;
        }
  
      }
  
      .lastExpertTitle{
        color:#586a80;
        font-family: 'Baskerville BT',serif;
        font-weight: normal;
        font-style: italic;
        font-size: 16px;
        min-height: 50px;
        text-align:center;
      }
  
  
      .expertButtonWrapper{
        display:flex;
        justify-content: center;
      }
  
      .lastExpertDetailBtn{
        // margin-top:20px;
        font-family: 'Baskerville BT',serif;
        padding-top: 6.5px;
        padding-bottom: 6.5px;      
        border-radius: 0;
        background:#384a5f;
  
        &:hover{
          background:#586a80 !important;
          color:#fff;
          transition: all 0.3s ease-out;
          cursor:pointer;
        }
  
      }
      
    }
  
  }
  


  .expertWrapper{
    display: grid;
    border-top:4px solid #84ac87;
    grid-template-columns: 0.5fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 30px;
    width: 100%;
    height: 100%;
    font-size: 1.3rem;
    padding-top:85px;   
    margin-top:30px;
  }
  
  .expertpublishs{
    color:#000;
  
    &:hover{
      color:#506c53;
    }
  }
  
  .expertContent{
    p{
      color:#000;
      font-size:1.4rem;
      font-family: 'Baskerville BT',serif;
      text-shadow: 0 0 #000;      
      word-wrap: break-word;
    }
  
    ul{
      list-style: none;
      margin-left:2px;
  
      li{
        color:#000;
        text-shadow: 0 0 #000;      
        font-size:1.4rem;
        font-family: 'Baskerville BT',serif;   

      }
    }
    
  }

  .socialMedyaInfo{
    word-break: break-all; 
  }

  .expertpublishs{
    word-break: break-all; 
  }
  
  .expertListSticky{
    position: sticky;
    top: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  
    .editsfilter{
      margin-top:0px;
    }
  
    .editfiltericon {
      position: absolute;
      left: 14px;
      top: 8px;
    }
  
    .buttonwrapper{
      width: auto !important;
  
      .btnFilterexpert{
        &:hover{
          cursor:pointer;
          background: #FF004B;
        }
      }
    }
  
    .react-dropdown-select {
      position: relative;
      margin-top: 0px;
      height: 40px;
      border-radius: 5px;
      border: 1px solid #3f5642;
      outline-color: #000 !important;
      max-width: 300px;
      width: 100%;
      font-weight: 300;
      font-size: 15px;
      font-family: 'Baskerville BT',serif;
      background:#fff;
  
      
    }
  
    .react-dropdown-select-input{
      position:absolute;
      top:5px;
      width: 100%;
  
      ::placeholder{
        font-size:15px;
      }
    }
  
    div[class*="ContentComponent"]{
      padding-left:5px;
      padding-right: 5px;
    }
          
  
  }
  
  .expertSectionTitle{
    font-family: "Baskerville BT",serif;
    font-size:1.8rem;
    line-height: 2rem;
    color:#000000;
    font-weight: bold;
    
  
  }
  
    .ulexpertList{
      list-style: none;
      font-size:1.4rem;
  
      li{
        margin-bottom: 10px;
        color:#000;
        font-weight: 500;
        font-size: 17px;
        font-family: 'Baskerville BT',serif;
        line-height: 17px;     
        text-align: center; 
  
        &.expertFilterTitle{
          color:#000;
          font-weight: 500;
          font-family: 'Baskerville BT',serif;
          line-height: 2.2rem;    
          font-size: 2.2rem;  
  
          .lastExpertName{
            margin-top:20px;
            color:#000;
            font-size:2rem;
            text-shadow: 0 0 #000;    
            font-family: 'Baskerville BT',serif;
            font-weight: lighter;
            margin-bottom:5px;
            text-align:left;
            display: flex;
            justify-content: flex-start;
            align-items: center;   
            
            
          }
          .lastExpertTitle{
            color:#000;
            margin-top:10px; 
            font-family: 'Baskerville BT',serif;
            font-weight: normal;
            font-style: italic;
            font-size:1.8rem;
            min-height: 50px;
            text-align:left;
          }
  
          .lastExpertContactTitle{
            color:#000;
            font-size:1.8rem;
            text-shadow: 0 0 #000;    
            font-family: 'Baskerville BT',serif;
            font-weight: bold;          
            margin-bottom:5px;
            text-align:left;
            display: flex;
            justify-content: flex-start;
            align-items: center;      
          }        
      
  
        }
  
      }
  
  
      .expertFilterContent{
        .inputWrapper.web{
          div{
            &:first-of-type{
              width:100%;
            }
          }
        }
      }
  
  
    }  

    .expertProfileImg{
      box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.20);
      margin-bottom:25px;
      width: 360px;
      height: 398px;
      border: 4px solid #84ac87;
    
    }
    .expertLoading{
      display:flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    
      p{
        width: 100%;
        text-align: center;
        font-size: 1.3rem;
        font-weight: 500;
    
      }
    }
    
    .expertSocialMedyaUl{
      list-style: none;
      margin-left:0px !important;
    
      .socialMedyaInfo{
        margin-left:0px;
        color:#000;
      }
    
      .socialLine{
        margin-top:0px;
        margin-bottom:0px;
      }
    
      li{
        margin-bottom:0px;
      }
    }
    
    .expertIntroUl{
      list-style: none;
      margin-left:0px !important;
      font-size: 1.4rem;
      font-family: 'Baskerville BT',serif;
    
    
      li{
        display:grid;
        grid-template-columns: 250px 1fr;
        margin-top:10px;
        font-size: 1.4rem;
        text-shadow: 0 0 #000;      
        font-family: 'Baskerville BT',serif;
        color: #000;
    
        div{
          display:flex;
          align-items: center;
          font-size: 1.4rem;
          text-shadow: 0 0 #000;      
          font-family: 'Baskerville BT',serif;
          color: #000;
    
          span{
            font-size: 1.4rem;
            text-shadow: 0 0 #000;      
            font-family: 'Baskerville BT',serif;
            color: #000;
          }
        }
    
        strong{
          margin-right:10px;
          color: #000;;
        }
    
        label{
          color: #000;
          font-size: 1.4rem;
          text-shadow: 0 0 #000;      
          font-family: 'Baskerville BT',serif;
          margin-bottom:0px;      
        }
    
      }
    }


    .sectionCVWrapper{
      padding-left:30px !important;
      padding-right: 30px !important;

        .cvImg{
          max-width: 240px;
          max-height: 289px;
          min-width: 240px;          
        }

    }

    .blogSectionWrapper{
      grid-template-columns: 1fr; 
    }

    .egitimGrid{
      margin-bottom:0px !important;
    }

    .egitimDetailWrapper{
      padding:30px;
    }

    .subContentTitle{
      font-size:3rem;
    }

    .farkindalikGrid{
      grid-template-columns: repeat(2, 1fr) !important;
    }

    .subContentTitleFAQ{
      font-size: 3rem !important;
    }

    .sectionNavBar{
      grid-template-columns: repeat(auto-fit, minmax(100px, 110px));    
    }
    .nav-links{
      font-size: 1.3rem !important;
    }    

  /***
  End of Other Pages
  ***/
  














  /***

    SignUp Page

  ***/  

  
  .navbar {
    grid-template-columns: repeat(auto-fit, minmax(100px, 170px)); 
    grid-gap: 20px;   
  }

  .headerContentWrapper{

    .webSectionTitle{
      font-size:28px;
      margin-top:20px;
    }
  }

  .mainSection{
    padding-left:20px;
    padding-right:20px;


    .sectionWrapper{
      max-width: 100% !important;
      padding-top:10px !important;
      height: calc(100vh - 320px);

      .sectionTitle{
        font-size:16px;
      }

      .gridWrapper{
        grid-gap:20px;

        
        

        .medyaDeneyimiRadioGroup{
          margin-bottom:20px;
        }
        .iletisimBilgileriCheckGroupPres{
          grid-template-rows: 40px 40px 40px;

          .edits{
            height: 35px;
          }

        }

        .contactWrapper {
          .edits{
            height: 35px;
          }
        }

        .infoAltyapi{
          font-size:12px;
          line-height: 19px;
        }

        .altyapiSub{
          font-size:12px;
          line-height: 19px;
          margin-bottom:5px;
        }

        .margin-top-x{
          margin-top:10px;
        }


      }
      .gridWrapperStep5{

        div{
          &:nth-of-type(2){
            max-width: 560px;
          }
        }
      }

      .componentWrapper{
        width: 100%;
        

        .gorus-konular-step2{
          .ulYayinReferanslar{
            height: 180px;
          }
        }

        .contentInfoWrapper{
          max-height: auto;
        }

        .gorus-konular{
          min-width: 100%;
          height: 100% !important;
        }

        .ulYayinReferanslar{
          overflow:hidden;
          overflow-y: auto;
          height: 200px;
          margin:0px;
        }

        .step4GorusKonular{
          height: 210px !important;
        }

        .step5SosyalMedya{
          max-width: 360px !important;
        }

        .ulSosyalHesaplar{
          .socialLine {
            a{
              width: 25px !important;
              height: 25px !important;
            }
          }
        }

      }

    }

    .inputWrapper{
      .edits{
        margin-top:5px;
        margin-bottom:5px;
      }
    }
    .memos{
      height: 230px;
    }

    .ant-upload-select {
      width:120px !important;
      height: 120px !important;
      margin:0px !important;
    }
    .ant-upload-list-picture-card-container {
      width:120px !important;
      height: 120px !important;      
    }    

    .ant-upload-list-item{
      width:120px !important;
      height: 120px !important;      

    }

    .imageToolbar{
      margin-bottom:0px;
    }    

    .buttonUpload {
      top:50px;
    }

  }

  footer{
    height: 65px;
    width: 100%; 
    display: flex;
    box-sizing: border-box;
    position:fixed;
    bottom:10px;
    padding-left:3%;
    padding-right:3%;     
    justify-content: space-between;    
  }
}

@media only screen and (min-width: 1451px) and (max-width: 1560px){
  .sliderWrapper {
    .sliderText{
      max-width: 680px ;
    }

    .sliderAttachRight{

    }

  }
}

@media only screen and (min-width: 1661px) and (max-width: 1750px){
  .sliderWrapper {
    padding-top:70px !important;
    .sliderText{
      max-width: 700px ;
    }
  }

  .sliderAttachRight{
    bottom:unset;
  }

}
@media only screen and (min-width: 1751px) and (max-width: 1919px){
  .sliderWrapper {
    padding-top:110px !important;
    .sliderText{
      max-width: 800px ;
    }
  }

  .sliderAttachRight{
    bottom:unset;
  }

}


@media only screen and (min-width: 1600px) and (max-width: 1919px){
  
  #mainID{
    // padding-left:150px;
    // padding-right:150px;
    
  } 

  .homeWrapper{
    padding-left:52px;
    padding-right:52px;
    display: block;
    max-width: 100%;    
  }

  .newSectionWrapper{
    display:block;
    width: 100%;
    height:100%;
    // max-width:1483px;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:200px;
  }  

  #navID{
    // padding-left:72px;
    // padding-right: 72px;
    // max-width:1483px;
    margin-left:auto;
    margin-right:auto;



  }

  .logoContainer img {
    max-height: 198px;
  }    

  .navbarsticky{
    left:unset;
    padding: unset;    
    max-width: fit-content;
    padding-right: 104px;
  }

  .navbarsticky .logoContainer img {
    max-height: 65px;
  }  

  .sliderID{
    // max-width:1483px;
    margin-left:auto;
    margin-right:auto;

  }

  .sliderWrapper{
    height:700px;
    background-size: 825px 543px;
    background-position: bottom 6px right 30px;
    padding-left:70px;
    padding-top:70px;

    .sliderQuote{
      min-width:300px;
      width:auto;
      font-size:3rem;
      line-height: 3rem;
      max-width: 650px;
    }

    .sliderText{

      &:first-of-type{
        margin-top:20px;
      }
    }

    .sliderAttachLeft{
      background-size: contain;
      background-repeat: no-repeat;
      width:45px;
      height: 36px;
      left:-50px;
    }
    .sliderAttachRight{
      background-size: contain;
      background-repeat: no-repeat;
      width:45px;
      height: 36px;
      bottom:-25px;      
    }

  }
  .filterSectionWrapperID{
    // max-width:1483px;
    margin-left:auto;
    margin-right:auto;
    background-size: 98%;


  }  

  .mainWorksWrapper{
    // max-width: 1483px;
    margin-left:auto;
    margin-right: auto;
    padding-top:122px;
    padding-bottom:122px;

    .mainWorksHeaderImg{
      max-width: 467px !important;
    }

    .mainWorksHeaderTitle{
      font-size: 2.7rem !important;
    }

    .workshopWrapper{
      grid-gap:40px;

      .workshop-image{
        // max-width: 461px !important;
        // max-height: 462px !important;
      }


      .workshop-title-wrapper{
        min-height: 130px;
      }

      .workshop-title{
        font-size:2.4rem;
        line-height: 2.4rem !important;
        margin-bottom:0px;
      }

      .workshop-content{
        font-size:1.35rem;
      }


    }


  }

  .lastExpertsContentWrapper{
    display:grid;
    // grid-template-columns: repeat(5,auto);
    // grid-template-columns: repeat(auto-fill, minmax(min(100px,200px), 1fr));
    // grid-template-columns: repeat(auto-fill, minmax(200px, 200px)) !important;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important;
    grid-gap: 28px;
    padding-top:30px;
    width: 100%;


  }

  #hakkindIDWrapper{
    // max-width:1483px;
    margin-left:auto;
    margin-right:auto;

  }
  #makalelerIDWrapper{
    
    // margin-left:auto;
    // margin-right:auto;

    .articlesSectionWrapper{
      padding-left:112px;
      padding-right: 112px;
    }


    .blogContentLogoImg{
      max-height:227px;
    }

    .blogContentBlogImg{
      object-fit: fill;
      width: 100%;
      height: 100%;
    }

    .articleItemTitle{
      font-size:3.5rem;
    }

  }
  #egitimlerIDWrapper{
    // max-width:1483px;
    margin-left:auto;
    margin-right:auto;

  }

  /* Home Page*/
  .filterSectionWrapper{
    min-height: 330px !important;
    // padding-bottom:60px;
  }

 


  .aboutlayerSectionWrapper{

    .webSectionTitle{
      margin-top:0px !important;
    }

  }

  .newFooter{
    height:615px;
    padding-top: 69px;
    padding-left: 57px;
    padding-bottom: 0px;
    padding-right: 57px;    

    .footerbar-logo{
      max-height: 215px;
    }

    .foot2 {
      p{
        font-size:1.7rem;

        &:last-child{
          font-size:1.7rem;
        }

      }
      
    }

    .foot4 {
      .faqWrapper{
        h5{
          font-size: 1.7rem;
        }
      }
    }  

  }  


  .sectionNavbar {
    grid-template-columns: repeat(auto-fit, minmax(100px, 180px));
  }



  /***

    SignUp Page

  ***/  

  
  .navbar {
    grid-template-columns: repeat(auto-fit, minmax(100px, 170px)); 
    grid-gap: 20px;   
  }

  .headerContentWrapper{

    .webSectionTitle{
      font-size:28px;
      margin-top:20px;
    }
  }

  .mainSection{
    padding-left:20px;
    padding-right:20px;


    .sectionWrapper{
      max-width: 100% !important;
      padding-top:10px !important;
      height: calc(100vh - 320px);

      .sectionTitle{
        font-size:16px;
      }

      .gridWrapper{
        grid-gap:20px;

        
        

        .medyaDeneyimiRadioGroup{
          margin-bottom:20px;
        }
        .iletisimBilgileriCheckGroupPres{
          grid-template-rows: 40px 40px 40px;

          .edits{
            height: 35px;
          }

        }

        .contactWrapper {
          .edits{
            height: 35px;
          }
        }

        .infoAltyapi{
          font-size:12px;
          line-height: 19px;
        }

        .altyapiSub{
          font-size:12px;
          line-height: 19px;
          margin-bottom:5px;
        }

        .margin-top-x{
          margin-top:10px;
        }


      }
      .gridWrapperStep5{

        div{
          &:nth-of-type(2){
            max-width: 560px;
          }
        }
      }

      .componentWrapper{
        width: 100%;
        

        .gorus-konular-step2{
          .ulYayinReferanslar{
            height: 180px;
          }
        }

        .contentInfoWrapper{
          max-height: auto;
        }

        .gorus-konular{
          min-width: 100%;
          height: 100% !important;
        }

        .ulYayinReferanslar{
          overflow:hidden;
          overflow-y: auto;
          height: 200px;
          margin:0px;
        }

        .step4GorusKonular{
          height: 210px !important;
        }

        .step5SosyalMedya{
          max-width: 360px !important;
        }

        .ulSosyalHesaplar{
          .socialLine {
            a{
              width: 25px !important;
              height: 25px !important;
            }
          }
        }

      }

    }

    .inputWrapper{
      .edits{
        margin-top:5px;
        margin-bottom:5px;
      }
    }
    .memos{
      height: 230px;
    }

    .ant-upload-select {
      width:120px !important;
      height: 120px !important;
      margin:0px !important;
    }
    .ant-upload-list-picture-card-container {
      width:120px !important;
      height: 120px !important;      
    }    

    .ant-upload-list-item{
      width:120px !important;
      height: 120px !important;      

    }

    .imageToolbar{
      margin-bottom:0px;
    }    

    .buttonUpload {
      top:50px;
    }

  }

  footer{
    height: 65px;
    width: 100%; 
    display: flex;
    box-sizing: border-box;
    position:fixed;
    bottom:10px;
    padding-left:3%;
    padding-right:3%;     
    justify-content: space-between;    
  }




}
@media only screen and (min-width: 1920px){




  
  #mainID{
    // padding-left:150px;
    // padding-right:150px;
  }  

  .homeWrapper{
    padding-left:72px;
    padding-right:72px;
  }

  .newSectionWrapper{
    display:block;
    width: 100%;
    height:100%;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:200px;
  }  

  #navID{
    // padding-left:72px;
    // padding-right: 72px;
    margin-left:auto;
    margin-right:auto;    
    max-width: 1776px !important;
    box-sizing: border-box;
  }

  .navbarsticky{
    .bottomMenu{
      margin-right:0px;
    }
  }  

  .sliderID{
    // padding-left:72px;
    // padding-right: 72px;
  }

  .sliderWrapper{
    height:790px;
    background-size: 992px 654px;
    background-position: bottom 8px right 40px;

    .sliderQuote{
      min-width:300px;
      width:auto;
      font-size:4rem;
      line-height: 4rem;
      max-width: 870px;
    }

    .sliderText{

      &:first-of-type{
        margin-top:40px;
      }
    }

  }
  .filterSectionWrapperID{
    // padding-left:72px;
    // padding-right: 72px;
  }  

  #hakkindIDWrapper{
    padding-left:72px;
    padding-right: 72px;    
  }
  #makalelerIDWrapper{
    // padding-left:72px;
    // padding-right: 72px;    
  }
  #egitimlerIDWrapper{
    padding-left:72px;
    padding-right: 72px;    
  }

  /* Home Page*/
  .filterSectionWrapper{
    min-height: 330px !important;
    // padding-bottom:60px;
  }

 


  .aboutlayerSectionWrapper{

    .webSectionTitle{
      margin-top:0px !important;
    }

  }

  .blogContentBlogImg{
    object-fit: fill;
    width: 100%;
    height: 100%;
  }  

  /***

    SignUp Page

  ***/  

  .navbarsticky{
    max-width: 1920px !important;
    padding-left:unset;
    padding-right: unset;
    left:unset;
  }

  .navbar {
    grid-template-columns: repeat(auto-fit, minmax(100px, 170px)); 
    grid-gap: 20px;   
  }

  .headerContentWrapper{

    .webSectionTitle{
      font-size:28px;
      margin-top:20px;
    }
  }

  .mainSection{
    padding-left:20px;
    padding-right:20px;


    .sectionWrapper{
      max-width: 100% !important;
      padding-top:10px !important;
      height: calc(100vh - 320px);

      .sectionTitle{
        font-size:16px;
      }

      .gridWrapper{
        grid-gap:20px;

        
        

        .medyaDeneyimiRadioGroup{
          margin-bottom:20px;
        }
        .iletisimBilgileriCheckGroupPres{
          grid-template-rows: 40px 40px 40px;

          .edits{
            height: 35px;
          }

        }

        .contactWrapper {
          .edits{
            height: 35px;
          }
        }

        .infoAltyapi{
          font-size:12px;
          line-height: 19px;
        }

        .altyapiSub{
          font-size:12px;
          line-height: 19px;
          margin-bottom:5px;
        }

        .margin-top-x{
          margin-top:10px;
        }


      }
      .gridWrapperStep5{

        div{
          &:nth-of-type(2){
            max-width: 560px;
          }
        }
      }

      .componentWrapper{
        width: 100%;
        

        .gorus-konular-step2{
          .ulYayinReferanslar{
            height: 180px;
          }
        }

        .contentInfoWrapper{
          max-height: auto;
        }

        .gorus-konular{
          min-width: 100%;
          height: 100% !important;
        }

        .ulYayinReferanslar{
          overflow:hidden;
          overflow-y: auto;
          height: 200px;
          margin:0px;
        }

        .step4GorusKonular{
          height: 210px !important;
        }

        .step5SosyalMedya{
          max-width: 360px !important;
        }

        .ulSosyalHesaplar{
          .socialLine {
            a{
              width: 25px !important;
              height: 25px !important;
            }
          }
        }

      }

    }

    .inputWrapper{
      .edits{
        margin-top:5px;
        margin-bottom:5px;
      }
    }
    .memos{
      height: 230px;
    }

    .ant-upload-select {
      width:120px !important;
      height: 120px !important;
      margin:0px !important;
    }
    .ant-upload-list-picture-card-container {
      width:120px !important;
      height: 120px !important;      
    }    

    .ant-upload-list-item{
      width:120px !important;
      height: 120px !important;      

    }

    .imageToolbar{
      margin-bottom:0px;
    }    

    .buttonUpload {
      top:50px;
    }

  }

  footer{
    height: 65px;
    width: 100%; 
    display: flex;
    box-sizing: border-box;
    position:fixed;
    bottom:10px;
    padding-left:3%;
    padding-right:3%;     
    justify-content: space-between;    
  }



}

@media not all and (min-resolution:.001dpcm) { 
  @supports (-webkit-appearance:none) {
    

    main.signup{
      overflow-y: auto;
    }

  .contentInfoWrapper.gorus-konular.country-city{
    height: 100% !important;
  }


    /*Nav Bar*/

    .navsubbarhome {
      background:#fff;
      height: 97px;
      width: 100%;
      display: block;
      justify-content: center;
      align-items: flex-end;
      flex-direction: column;
      font-size: 1.4rem;
      position: fixed;
      top: 0;
      z-index:2;
      
      padding-bottom:15px;
      transition: all 0.5s ease;   
      
    }    

    /*End of Top */


    /* SignUp */

    .componentWrapper.step2-layer1 .contentInfoWrapper{
      padding-top:20px;
    }

    .checkContainer{
      margin-bottom:35px;
    }

    .react-dropdown-select-input {
      margin-top:-5px !important;  
    }

    .mainSection3 .contentSpacer{
      margin-top:5px !important;
    }


    .baslangicWrapper{
      display: unset;
    }

    .btnWeb.btnBasla{
      -webkit-appearance:"none";
      text-align: center;
      margin-right: auto;
      margin-left:auto;
    }

  }
}

.homeWrapper{
  display:block !important;
  margin-left:auto;
  margin-right:auto;
  overflow-x: hidden;
  max-width: 1920px;
}

.inputWrapperSearch{
  width:100%;
}
.Triangle_573_copy {
  position: absolute;
  left: 8243px;
  top: 4663px;
  width: 41px;
  height: 51px;
  z-index: 447;
}

.expertsearchpage{
  .newContentLayerWrapper{
    background: #f6f5f3 !important;
    padding-left:40px;
    padding-right:40px;
    margin-bottom:80px;
  }
}

.blogPageImg{
  max-width: 794px;
  margin-left:auto;
  margin-right:auto;
}

.nomarginbottom{
  margin-bottom:0px !important;
}