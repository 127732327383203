.sliderID{
  display:flex;
  position: relative;
  width: 100%;
  height: 100%;
  
}
.sliderWrapper{
  position:relative;
  width:100%;
  display:flex;
  height:790px;
  background:url('../../../assets/images/banner-right.png');
  background-color:#84ac87;
  background-repeat: no-repeat;
  background-size: 992px 654px;
  background-position: bottom 8px right 40px;
  flex-direction: column;
  padding-left:140px;
  padding-top:85px;

}

.sliderTitle{
  display:block;
  width: auto;
  max-width: 560px;
  text-align: center;
  background:rgba(59,69,89,0.6);
  color:#fff;
  padding-left:7px;
  padding-right:7px;
  font-size:3rem;
  font-weight: 700;
  margin-bottom:4px;
  font-family: 'Baskerville BT',serif;

  &:nth-of-type(2){
    margin-bottom:15px;
  }
}

.btnWatchVideo{
  position:absolute;
  display:flex;
  align-items: center;
  justify-content: space-around;
  bottom:55px;
  left:90px;
  width:375px;
  height: 75px;
  border:none;
  background:#4b4b4b;
  background-repeat: no-repeat;
  outline: none;
  padding:0px;
  cursor: pointer;
  color:#000000;
  font-family: 'Baskerville BT',serif;
  font-weight: 700;
  font-size:19px;
  // font-family: 'Baskervville', serif;

  &:hover{
    box-shadow: 0 6px 35px 0 rgba(0, 0, 0, 0.25);
  }

  img{
    max-height: 27px;
  }
}

.sliderQuoteWrapper{
  position:relative;
  display:block;
  
}

.sliderQuote{
  min-width:300px;
  width:auto;
  color:#ffffff;
  font-family: 'Baskerville BT',serif;
  font-weight: 500;
  font-size:4rem;
  line-height: 4rem;
  margin-bottom:0px;
}

.sliderText{
  position:relative;
  max-width:560px;
  width: 100%;
  color:#fff;
  font-family: 'Baskerville BT',serif;
  font-size:1.45rem;
  line-height: 1.7rem;

  strong{
    color:#fff;
  }

}

.sliderAttachLeft{
  position:absolute;
  left:-100px;
  top:-40px;
  width:55px;
  height:46px;
  background:url('../../../assets/images/left-quote.png');  

}

.sliderAttachRight{
  position:absolute;
  width:55px;
  height:45px;
  background:url('../../../assets/images/right-quote.png');
  right:0px;
  bottom:-60px;
}