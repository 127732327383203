.gridWrapperNew4{
  width:100%;
  max-width: 1500px;
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 28px;

  .sectionTitle{
    text-align:center;
    font-family: "Baskerville BT",serif;
    font-size:1.7rem;
    font-weight: normal;
    color:#fff;
    width:100%;

    &.tlast{
      text-align:left;
    }


  }

  .linkInfo{
    width: 100%;
    text-align: left;
    font-family: "Baskerville BT",serif;
    font-size:1.6rem;  
    font-style: normal;  
    color:#343d35;
    margin:0px;
  }  

  .buttonBaglantiEkle{

  }

}
.gridWrapperNew4 > div{
  display:flex;
  flex-direction: column;
  align-items: center;

  &:first-child{
    align-items: flex-start;
  }
}

.medyaDeneyimiRadioGroup{
  margin-top:20px;
  display:flex;

}

.medyaDeneyimiRadioWrapper{
  display:flex;
  width:100%;
  justify-content: center;
}

.linkInfo{
  font-size:12px;
  font-style: italic;
  color:#777;
  padding:0px;
  font-family: 'Roboto',sans-serif;
}


.baglantiWrapper{
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top:5px !important;
}

.buttonBaglantiEkle{
  margin-left:5px;
  background:#506c53;
  color:#fff;
  padding-left:10px;
  padding-right: 10px;
  width:100px;
  height: 40px;
  font-weight: normal;
  font-size:15px;
  border-radius: 5px;
  border:none !important;
}

.gorus-konular{
  min-width: 350px;
}
.componentWrapper{
  margin-top:0px;
  
  .step4-layer1{
    height: 230px;
  }
}

.step4GorusKonular{
  margin-top:15px;
  min-height: 250px !important;
  background:transparent;
  border:none;
  box-shadow: none;
  color:#fff;
  padding:0px;
}

.ulYayinReferanslar{
  list-style: none;
  position:relative;
  width: 100%;
  height: 100% !important;
  
  
}

.ulYayinReferanslar li{
  display:flex;
  line-height: 15px;
  margin-top:5px;
  margin-bottom:5px;
  align-items: center;
  flex-direction: row;
  flex-wrap:nowrap;
}

.ulYayinReferanslar li span{
  overflow: hidden;
  text-overflow: ellipsis;
  width: 280px;
  font-family: "Baskerville BT",serif;
  white-space: nowrap;
  font-size: 16px;
  color:#fff;
  margin-left:3px;
}

.btn-remove-list{
  position:relative;
  display:block;
  width:12px;
  height:12px;
  background:url(../../assets/images/remove-list.png);
  background-size: cover ;
  border:none;
  margin-right:5px;
  outline: none;

  &:hover{
    cursor:pointer;
  }
}

.medyaDeneyimiRadioGroup{
  display:flex;
  flex-direction: column;
  align-items: center;

  label{
    font-family: "Baskerville BT",serif;
    font-weight: bold;
    font-size:19px;
    color:#343d35;
  }
  .radioContainer{
    min-width:95px;
  }

}

.btn-save-new{
  width:246px;
  height:88px;
  display:flex;
  background:#506c53;
  font-family: "Baskerville BT", serif;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  font-size: 2rem;
  align-items: center;
  justify-content: center;
  border:none;
  margin-top:10px;
}
