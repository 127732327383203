.sectionStepNew{
  display:flex;
  width:100%;
  padding:50px;
  flex-direction: column;
  background:#84ac87;
  margin-bottom:382px;
}


.sectionWrapperNew{
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top:80px;

  .sectionTitleNew{
    color:#fff;
    font-family: "Baskerville BT",serif;    
    font-size:1.8rem;
  }

  .imageFigure{
    display:flex;
    justify-content: center;
    align-items: center; 
    position: relative;
    margin-bottom:50px;
    
  }
  .iconFemale{
    height:92px;
    width:86px;
    stroke:#c2b7b1;
    display: block;
    position: relative;
    top:15px;
    margin:auto;
  }
  
  .imageToolbarNew{
    position: relative;
    width: 800px;
    height:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    margin-top:0px;
    margin-bottom:0px;

    .imageToolbarLeft{
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

  }
  .buttonTransparent{
    background:none;
    border:none;
    width:50px;
    height:50px;
    cursor: pointer;
  }
  
  .buttonUploadHidden{
    display:none;
  }

  .buttonUploadNew{
    position:relative;
    background:none;
    border:none;
    width:313px;
    height:88px;
    cursor: pointer;
    background-color:#668e69;
    transition: background-color 0.3s;
    color:#fff;
    font-size: 1.8rem;
    text-align: center;
    font-family: "Baskerville BT",serif;    
    outline:none;
    box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.1);  
  
    &:hover{
      cursor: pointer;
      background-color:#506c53;
    }
  
  }
  
  .uploadContentWrapper{
    display: flex;
    flex-direction: column;
    align-items:center ;
  }
  
  .titleAdsoyad{
    margin-top:60px;
  }
  
  
  .img-remove{
    width:30px;
  }
  .img-upload{
    width: 30px;
  }
  
    
  // input[type="file"] {
  //   display: none;
  // }
  
  .ant-upload-list-picture-card{
    display:flex;
    justify-content: center;
  }
  
  .ant-upload.ant-upload-select-picture-card{
    margin-right:0px;
    box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.1);  
  }
  .ant-upload-select{
    border-radius: 0% !important;
    background: #f1f1f1;
    width: 345px !important;
    height: 375px !important;
    display: block;
    border: none !important;
    
    // border: 3px solid #506c53 !important;
    margin-top: 0px !important;
    margin-bottom: 0px;  
  
    &:hover{
      border: none !important;
      
      .buttonUpload{
        cursor: pointer;
        background-color:#506c53;
        transition: background-color 0.3s;
    
      }
    }
  }
  
  .ant-upload-list-picture-card{
    padding:0px !important;  
  }
  
  .ant-upload-list-item{
    border-radius: 0 !important;
    background: #f1f1f1;
    width: 345px !important;
    height: 375px !important;
    display: block;
    border: 3px solid #506c53 !important;
    margin-top: 20px;
    margin-bottom: 20px;    
    overflow: hidden;
    padding:0px !important;
  }
  
  .ant-upload-list-picture-card-container{
    width: 345px !important;
    height: 375px !important;
    margin:0px !important;
    margin-top:20px !important;
  }
  
  .anticon{
    svg{
      fill:#fff;
    }
  }
  
  .inputWrapper{
    width:100%;
    display:flex;
    justify-content: center;
  }
  
  .inputError{
    &:after{
      content:'';
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDI4Ni4wNTQgMjg2LjA1NCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjg2LjA1NCAyODYuMDU0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8Zz4NCgk8cGF0aCBzdHlsZT0iZmlsbDojRTI1NzRDOyIgZD0iTTE0My4wMjcsMEM2NC4wNCwwLDAsNjQuMDQsMCwxNDMuMDI3YzAsNzguOTk2LDY0LjA0LDE0My4wMjcsMTQzLjAyNywxNDMuMDI3DQoJCWM3OC45OTYsMCwxNDMuMDI3LTY0LjAyMiwxNDMuMDI3LTE0My4wMjdDMjg2LjA1NCw2NC4wNCwyMjIuMDIyLDAsMTQzLjAyNywweiBNMTQzLjAyNywyNTkuMjM2DQoJCWMtNjQuMTgzLDAtMTE2LjIwOS01Mi4wMjYtMTE2LjIwOS0xMTYuMjA5Uzc4Ljg0NCwyNi44MTgsMTQzLjAyNywyNi44MThzMTE2LjIwOSw1Mi4wMjYsMTE2LjIwOSwxMTYuMjA5DQoJCVMyMDcuMjEsMjU5LjIzNiwxNDMuMDI3LDI1OS4yMzZ6IE0xNDMuMDM2LDYyLjcyNmMtMTAuMjQ0LDAtMTcuOTk1LDUuMzQ2LTE3Ljk5NSwxMy45ODF2NzkuMjAxYzAsOC42NDQsNy43NSwxMy45NzIsMTcuOTk1LDEzLjk3Mg0KCQljOS45OTQsMCwxNy45OTUtNS41NTEsMTcuOTk1LTEzLjk3MlY3Ni43MDdDMTYxLjAzLDY4LjI3NywxNTMuMDMsNjIuNzI2LDE0My4wMzYsNjIuNzI2eiBNMTQzLjAzNiwxODcuNzIzDQoJCWMtOS44NDIsMC0xNy44NTIsOC4wMS0xNy44NTIsMTcuODZjMCw5LjgzMyw4LjAxLDE3Ljg0MywxNy44NTIsMTcuODQzczE3Ljg0My04LjAxLDE3Ljg0My0xNy44NDMNCgkJQzE2MC44NzgsMTk1LjczMiwxNTIuODc4LDE4Ny43MjMsMTQzLjAzNiwxODcuNzIzeiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);    
      width:30px;
      height:30px;
      margin-left: 5px;
      margin-top: 25px;
    }
  }
  
  .mainInfoWrapper{
    display:flex;
    width:100%;
    justify-content: center;
    margin-top:40px;
  
    .mainInfoItem{
      max-width: 500px;
      width: 100%;
      margin-right:35px;
  
      h4{
        text-align: center;
      }
  
    }

    .edits-login{
      height:85px;
      border-radius: 0px;
      border:1px solid #c2c1bd;
      padding-left:15px;
      font-size:1.7rem;
      margin-bottom:20px;
      max-width: 600px;
      width: 100%;
      font-family: "Baskerville BT",serif;
      outline-color: #3f5642;
  
      &:last-of-type{
        margin-bottom:0px;
      }
  
    }
  
    .titleAdsoyad{
      color:#fff;
      font-size:2.1rem;
      font-family: "Baskerville BT",serif;
      font-weight: normal;
    }    
  
  }
  
  .ant-slider-handle{
    border: solid 2px #506c53;
  }
  
  .ant-slider-handle.ant-tooltip-open{
    border: solid 2px #ff964c;
  }  


}


