.newContentLayerWrapper{
  display:flex;
  flex-direction: column;
  background: #f6f5f3;
}

.spacebottom{
  margin-bottom:55px;
}

.blogbg{
  background:#f6f5f3;
}

.blogSectionWrapper{
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(2, 1fr);
  padding:62px;
  grid-gap: 65px;
  // margin-bottom:100px;
  // padding-bottom:70px;

  .articleContentHeader{
    position:relative;
  }


  .articleContentBlogImg{
    max-width: 720px;
    max-height: 273px;
    width: 100%;
    height: 100%;
  }

  .articleItem{
    position:relative;
    display:flex;
    flex-direction: column;
    justify-content:  space-between;

    .articleContentTop{
      display:flex;
      position:relative;
      width:auto;
      height:auto;
      justify-content: center;

    }

    .articleItemTitle{
      position:absolute;
      top:125px;
      right:10px;
      color:#84ac87;
      font-family: "Baskerville BT",serif;
      font-size: 4rem;
      font-style: italic;   
      text-underline-offset: 10px;  
      text-decoration-thickness: 4px;
      margin-bottom:0px;
      margin-top:10px;                 
    }


    .articleItemContent{
      font-family: "Baskervville", serif;
      font-size:1.4rem;
      color:#000000;
      color: #000;
      font-weight: lighter;
      text-shadow: 0 0 #000;      
      margin-top:30px;          
    }

    .linkToBlog{
      color:#84ac87;
      text-decoration:underline;
      font-weight: 600;
      text-shadow: none;
      text-underline-offset: 5px;
    }

  }

}
