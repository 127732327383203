.homeWrapper{
  position: relative;
  display: table;  
  width:100%;
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */    
}
.newContentLayerWrapper{
  display:flex;
  flex-direction: column;
  background: #fff !important;
}

.bgGrey{
  background: #f6f5f3 !important;
}

.spacebottom{
  margin-bottom:55px;
}

.spacerTop75{
  margin-top:75px;
}

.subContentSectionWrapper{
  display: flex;
  flex-direction: column;
  align-items:center;
  width:100%;
  height:100%;

  .imgMentorluk{
    max-width: 755px;
    max-height: 741px;
    width: 100%;
    height: auto;
    margin-bottom:30px;
  }  

  .subContentTitle{
    color:#84ac87;
    font-family: "Baskerville BT",serif;
    font-style: italic;
    font-size: 4rem;
    line-height: 4rem;
    text-shadow: none;
    
  }
  .subContentTitle2{
    color:#84ac87;
    text-shadow: none;
    font-family: "Baskerville BT",serif;
    font-style: italic;
    font-size: 4rem;
    line-height: 4rem;
    margin-top:60px;
    margin-bottom:60px;
  }

  .subContentParagraph2{
    font-family: "Baskerville BT",serif;
    font-size:1.3rem;
    line-height: 2rem;
    color:#000000;
    font-weight: lighter;
    text-shadow: 0 0 #000;      
    max-width: 1255px;
    width:100%;
    text-align: left;
   
    word-wrap: break-word;

    b{
      font-weight: bold;
      color:#000;
    }

    margin-bottom:130px;

  }

  .ulProjeEkibi{
    display:flex;
    flex-direction: column;
    list-style: none;
    padding:0px;
    margin:0px;
    margin-bottom:100px;

    li{
      font-family: "Baskervville", serif;
      font-size:1.8rem;
      line-height: 1.8rem;
      color:#000000;
      font-weight: lighter;
      text-shadow: 0 0 #000;            
      margin-bottom:10px;

      &:hover{
        cursor:pointer;
        color:#84ac87;
      }

      &.selected{
        color:#84ac87;
        text-shadow: 0 0 #84ac87;  
      }

    }

  }





  .egitimGrid{
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap:17px;
    margin-bottom:90px;
    padding:36px;
  }

  .egitimCol{
    position:relative;

    .egitimColImg{
      max-width: 562px;
      max-height: 554px;
      width:100%;
      height: auto;
      border:8px solid #84ac87;      
    }

    .egitimColText{
      position:relative;
      margin-top:15px;
      font-family: "Baskervville", serif;
      width:80%;
      font-size:1rem;
      line-height: 1.3rem;
      color:#5d765e;
      font-weight: lighter;
      text-shadow: 0 0 #5d765e;            

    }
  
  }

  .egitimDetailWrapper{
      position: relative;
      width:100%;
      max-width: 1200px;
      margin-bottom:250px;

    
    .egitimTitle{
      position:relative;
      width:100%;
      text-align: left;
      font-family: "Baskervville", serif;
      font-weight: lighter;
      text-shadow: 0 0 #84ac87;           
      color:#84ac87;
      font-size:1.8rem;
    }

    .egitimUl{
      list-style-type: none;
      padding-left:20px;

      .egitimLi{
        font-family: "Baskerville BT",serif;
        font-size:1.3rem;
        line-height: 1.5rem;
        color:#000000;
        font-weight: normal;
        text-shadow: 0 0 #000;            
        margin-bottom:10px;

        &:before{
          content: '-';
          position: absolute;
          margin-left: -20px;          
        }
      }
    }

  
  }

}
