/* Warning Layer  */
.warningLayer {
  position: fixed;
  visibility: hidden;
  display: block;
  width: 100%;
  bottom: 0px;
  left:0px;
  height: 92px;
  z-index: 99999999999999 !important;
  background-color:rgba(51, 68, 53, 0.9);

  .warningInfo {
    position: relative;
    height: 92px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    line-height: 92px;
  }
  
  .warningImg {
    float: left;
    position: relative;
    margin-top: 26px;
    margin-right: 20px;
    margin-left: 40px;
    width: 40px;
  } 
  .warningText {
    position: relative;
    float: left;
    font-size: 20px;
    color: #FFFFFF;
    font-family: 'Baskerville BT',serif;
  }   
  .closeImg {
    position: absolute;
    top: 10px;
    right: 10px;
  }  

  .btnCloseImg{
    border:none;
    background:none;

    &:hover{
      cursor:pointer;
    }
  }
}
.warning-show{
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s linear;
}

.warning-hide{
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.5s, opacity 0.5s linear;
}

@media screen and (max-height: 600px) {

  .warningText{
    font-size:14px !important;
    padding-left:4px;
  }

  .warningLayer .warningImg{
    display:none;
  }
  

}

@media only screen and (max-width: 600px) {
  .warningText{
    font-size:12px !important;
    padding-left:10px;
  }

  .warningLayer .warningImg{
    display:none;
  }  
}


/* End of Warning Layer  */