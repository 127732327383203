.mainSection{
  
}

.sectionWrapper{
  padding-top:40px !important;
  max-width: 1200px !important;
}

.componentContainer{
  margin-top:30px;
  width:100%;
  max-width:900px;
  background: #fff;
  border-radius: 0px;
  // box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.1);
  display:block;
  min-height:200px;
}

.gridWrapperNew3{
  width:100%;
  max-width: 100%;
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 28px;

  .sectionTitle{
    text-align:left;
    font-family: "Baskerville BT",serif;
    font-size:1.7rem;
    font-weight: normal;
    color:#fff;
    width:100%;
    
  }
}
.gridWrapperNew3 > div{
  display:flex;
  flex-direction: column;
  align-items: center;

  &:first-child{
    align-items: flex-start;
  }
}


.componentWrapper{
  margin-top:20px;
  position:relative;
}

.contentInfoWrapper{
  padding:7px;
  display:flex;
  flex-direction: column;
  height:100%;
  max-width:350px;
  min-width: 300px;
  width:100%;
  max-height: 400px;
  overflow-y:auto;
  border:1px solid #ebebeb;
  border-radius: 0px;
  background:#fff;
  box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.1);


  fast-checkbox{
    margin-top:5px;
    margin-bottom:5px;
  }

  .contentInfo{
    padding-top:0px;
    font-size:15px;
    margin-bottom:0px;
  }



  .react-dropdown-select{
    margin-top:0px;
    border-radius: 0px;
    border:1px solid #3f5642;
    outline-color: #3f5642 !important;
    font-size:1.7rem;
    background: #fff;
    height:85px;
  }

  .react-dropdown-select-dropdown{
    border-radius: 0px;  
    font-size:1.7rem !important;
  }


}


.gorus-konular,.ulkeler-bolgeler{
  padding-left:20px;
  padding-right:20px;
  // min-height: 400px;
}

.gorus-konular{
  max-height: unset !important;
  height:auto !important;
  // min-height:400px !important;
  overflow-y: auto !important;


}


.editSubject{
  margin-top:10px;
}
.tooManyLetters{
  color:red;
}
.customSelect{
  padding:10px;
  
}

.mainSection3{
  .componentWrapper {
    margin-top:15px !important;
  }
}

.letterCount{
  display:none !important;
}
.contentSpacer{
  margin-top:30px;
}

.country-city{
  top: 0px;
  position: relative;
  min-height: unset !important;
  border:none;
  border-radius: 0px;
  background:none;
  box-shadow: none;
  padding:0px !important;
  height: 300px !important;
  border:none !important;


  .edits-login{
    max-width: 100% !important;
  }
}

.componentWrapper.country-city-step3{
  position: relative;
  margin-top:0px !important;
  height: 230px;

 


  .select-country-city{
    // height: 40px !important;
    margin-bottom:5px;
    margin-top:0px !important;

    .react-dropdown-select-type-single > span:first-of-type{
      font-size:1.7rem;
      font-family: "Baskerville BT",serif;
      align-self: center;
      justify-self: center;
      // position: absolute;
      padding:2px;
    }     

    .react-dropdown-select-dropdown{
      max-height: 180px;
    }

  }  
}

.edits-login{
  height:85px;
  border-radius: 0px;
  border:1px solid #c2c1bd;
  padding-left:15px;
  font-size:1.7rem;
  margin-bottom:20px;
  max-width: 600px;
  width: 100%;
  font-family: "Baskerville BT",serif;
  outline-color: #3f5642;

  &:last-of-type{
    margin-bottom:0px;
  }

}



div[class*="ReactDropdownSelect"]{
  &:hover{
    color:#3f5642 !important;
    border-color: #3f5642 !important;
  }
  
}

.react-dropdown-select-item-selected{
  background:#3f5642 !important;
}

.gridWrapperNew3{

  .checkContainer{
    font-size:1.7rem;
    font-family: "Baskerville BT",serif; 
    color:#343d35;   
    margin-top:35px;
  }
}

.memos-new {
  height: 185px;
  width: 100%;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 0px;
  border:1px solid #c2c1bd;
  margin-top: 0px;
  font-size: 17px;
  margin-bottom: 20px;
  box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.1);
  font-family: "Baskerville BT",serif;
  outline-color: #3f5642;  

}