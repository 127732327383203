.chatFormWrapper-content{
  border-radius: 0px;
  max-width: 915px;
  width: 100% !important;
  min-width: 300px;
  

  .chatWindowTitle{
    font-family: "Montserrat", sans-serif;
    text-shadow: 0 0 #3e4a5e;
    color: #3e4a5e;
    font-size: 32px;
    font-weight: bold;
    margin:0px;
  }  

  h2{
    font-size:24px;
  }

  hr{
    background: none;
    border: 0px;
    border-bottom: 1px solid rgba(0,0,0,0.2);  
    margin:0px;
    margin-top:10px;
    margin-bottom:10px;
    display: none;

  }

  h4{
    font-size:19px;
  }

  .fixclose{
    outline: none;
    top:30px;
  }

  .modalForm{
    height: auto !important;
    max-height: 90%;
    padding:30px !important;
  }

  .edits{
    margin-top:0px;
    max-width:100%;
    margin-bottom:0px;
    margin-right: 30px;
  }
  
  .chatSectionWrapper {
    display: grid;
    width: 100%;
    height: 400px;
    grid-template-columns: 250px 1fr;
    grid-template-rows:100%;
    grid-template-areas: "contacts messages";
    grid-column-gap: 10px;
    margin-top:10px;

    .chatContacts{
      display:flex;
      width: 100%;
      grid-area: contacts;
      border:1px solid rgba(0,0,0,0.2);
      border-radius: 10px;
  

    }

    .chatMessages{
      display:flex;
      width:100%;
      padding:10px;
      border:1px solid rgba(0,0,0,0.2);
      border-radius: 10px;

    }

    .messagesWrapper{
      display:grid;
      width: 100%;
      height:100%;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 80px;
      grid-row-gap: 10px;


      .messageListFooter{
        padding:5px; 

        .textCountWrapper{
          position: relative;
          display: block;
          padding-left:2px;
          color:#404040;

          &.limit{
            color:red;
          }

        }

        .messageTextWrapper{
          display:flex;
          flex-direction: row;
          align-items: center;
        }
  
        .btnSend{
          display: block;
          height: 45px;
          width:140px;
          background-color: #3e4a5e;
          color: #fff;
          border: none;
          outline: none;
          font-size: 19px;
          border-radius: 0px;
          font-weight: bold;
          transition: background-color 0.3s;
          
          &:hover{
            background-color: #FF004B;
            cursor:pointer;
          }
          
        }        

      }



    }


  }


  .messageRefreshButton{
    display:inline-flex;
    max-height: 40px;
    position: relative;
    top:-2px;
    margin-left:10px;

    &:hover{
      cursor: pointer;
    }

  }

}

