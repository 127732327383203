.navbarhome {
  position: relative;
  margin-top:62px;
  background:rgba(255,255,255,1);
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  font-size: 1.3rem;
  position: relative;
  top: 0;
  z-index: 999;
  margin-bottom:33px;
  //transition: all 0.5s ease;   
  // max-width: 1776px;
}

.navbarhome-container {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: flex-start;
  height: auto;
  width: 100%;
}

.navbarhome-logo {
  justify-self: start;
  // margin-left: 20px;
  max-width: 630px;
  cursor: pointer;
  height: auto;
  max-height: 236px;
  width: 100%;
  // max-width:404px;
  text-decoration: none;
  display: flex;
  align-items: center;
  //transition: all 0.5s ease; 
  // transition: all 0.5s ease; 
  // box-shadow: 0 6px 35px 0 rgba(0, 0, 0, 0.25);
  // border-radius: 50%;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  // width: 60vw;
  width: 100%;
  justify-content: end;
  padding-right:70px
}


.nav-item {
  height: 40px;
  display: flex;
}

.rightMenuButton{
  position: absolute;
  border:none;
  background:none;
  align-self: center;
  justify-self: center;
  outline: none;
  right:23px;
  width:30px;
  height: 30px;
  opacity: 1;
  transition: all 0.5s ease; 
  font-size:1.8rem;
  color:#8b8a8f;

  &:hover{
    cursor:pointer;
    color:#384a60;
  }

  &.hide{
    opacity: 0;
    transition: all 0.5s ease; 
  }

}


.fa-times{
  color: #384a60;
}

.fa-bars {
  color: #384a60;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
  z-index:1;
}

.nav-menu-sub {
  position: absolute;
  display: flex;
  grid-template-columns: repeat(3, auto);
  grid-gap: 15px;
  list-style: none;
  text-align: center;
  // width: 60vw;
  width:100%;
  bottom:0px;
  right:0px;
  justify-content: flex-end;
  align-items:center;
  margin-bottom:0px;  
  font-size:1.2rem;
  font-weight: 500;
  
}

.nav-item-sub {
  font-family: "Montserrat", sans-serif;
  color:#84ac87;
  margin-left:10px;


  &:nth-of-type(3){
    font-family: "Montserrat", sans-serif;
    color:#fff !important;
    font-weight: normal;
    font-size:1rem;
    // margin-left:70px;

  }

}


.navbarhome.smaller{
  height:80px;
  //transition: all 0.5s ease; 
  box-shadow: 0 5px 5px rgba(116,116,116,.25);
  background: rgba(255,255,255,0.9);

  & .navbarhome-logo{
    max-height:70px;
    top:-40px;
    //transition: all 0.5s ease; 
    
  }

  & .navbarhome-container {
    height:20px;
  }

  & .nav-menu-sub {
    top:5px;
  }

}

.nav-item-for-mobile{
  display:none;
}

.navmenuCloseBtn{
  background:url('/assets/images/close-button-white.png');
  width:38px;
  height:38px;
  display:block;
  position:absolute;
  top:12px;
  right:31px;
  border:none;
  outline:none;
  cursor:pointer;
}

.nav-menu-sub-left {
  position: absolute;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 4px;
  list-style: none;
  text-align: center;
  // width: 60vw;
  max-width: 200px;
  width:100vw;
  top:27px;
  left:0px;
  justify-content: start;
  z-index:1;
  margin-bottom:0px;
}

.nav-item-sub-left {
  position:relative;
  font-family: "Montserrat", sans-serif;
  color:#000000;
  margin-bottom:0px;
  left:80px;
  align-items: center;  

  &:nth-of-type(3){
    font-family: "Montserrat", sans-serif;
    color:#FF004B !important;
    font-weight: bold;

  }

}

.nav-menu-sub-right {
  position: absolute;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 4px;
  list-style: none;
  text-align: center;
  // width: 60vw;
  max-width: 200px;
  width:100vw;
  top:27px;
  right:0px;
  justify-content: start;
  z-index:1;
  margin-bottom:0px;
}

.nav-item-sub-right {
  position:relative;
  font-family: "Montserrat", sans-serif;
  color:#000000;
  margin-bottom:0px;
  align-items: center;  
  margin-left:0px;
}


.img-katilim{
  width:33px;
  height:33px;
}

.img-bilgilerim{
  width:33px;
  height:33px;
}

.img-mesajlar{
  width: 28px;
  height: 28px;
  top: -2px;
  position: relative;
  margin-right: 5px;

}


.langbg{
  background:url('/assets/images/langbg.png');
  width:50px;
  height:41px;
  line-height: 41px;
  display: block;
}

.logoContainer{
  position: relative;
  margin-bottom:0px !important;
}
.linka{
  font-family: "Montserrat", sans-serif !important;
  color: #84ac87 !important;

  &:hover{
    text-decoration: underline solid #84ac87;
    text-underline-offset:11px;
    text-decoration-thickness: 3px;
    transition: all 0.5s ease 0s;          
    cursor: pointer;
  }

}

.loginFormWrapper-content{
  border-radius: 0px;
  max-width: 615px;
  width: 100% !important;
  min-width: 300px;
  font-family: 'Baskerville BT',serif;

  h2{
    font-size:24px;
  }

  hr{
    background: none;
    border: 0px;
    border-bottom: 1px solid rgba(0,0,0,0.2);  
    margin:0px;
    margin-top:10px;
    margin-bottom:10px;

  }

  h4{
    font-size:19px;
    font-family: 'Baskerville BT',serif;
  }

  .fixclose{
    outline: none;
    top:30px;
  }

  .modalForm{
    height: auto !important;
    max-height: 500px;
    padding:30px !important;
  }

  .edits{
    margin-top:0px;
    max-width:100%;
    border:1px solid #384a60;
    border-radius: 0;
  }

  .loginTitle{
    font-family: 'Baskerville BT',serif;
    text-shadow: 0 0 #3e4a5e;
    color: #3e4a5e;
    font-size: 32px;
    font-weight: bold;
    margin:0px;
  }

  .loginSection {
    .edits{
      &:last-of-type{
        margin-bottom:10px;
      }
    }

    .btnForgetPassword{
      background: none;
      border:none;
      color:#586a80;

      &:hover{
        cursor:pointer;
        color:#384a60;
      }
    }

    .btnForgetPasswordSignUp{
      margin-left:10px;
    }

    .loginSectionFooter{
      margin-top:40px;
      display:flex;
      justify-content: space-between;
    }

    .btnLogin{
      margin-top: -20px;
      padding-left: 40px;
      padding-right: 40px;   
      border-radius: 0;
      background:#000;
      
      &:hover{
        background:#384a60;
        cursor:pointer;
      }
    }

  }


  .forgetSection {
    .edits{
      &:last-of-type{
        margin-bottom:10px;
      }
    }

    .btnForgetPassword{
      background: none;
      border:none;
      color:#384a60;
      font-weight: bold;

      &:hover{
        cursor:pointer;
        color:#FF964B;
      }
    }

    .btnForgetPasswordSignUp{
      margin-left:10px;
    }

    .loginSectionFooter{
      margin-top:40px;
      display:flex;
      justify-content: space-between;
    }

    .btnLogin{
      margin-top: -20px;
      padding-left: 40px;
      padding-right: 40px;   
      
      &:hover{
        background:#384a60;
        cursor:pointer;
      }
    }

  }

}

.linkToLogin{
  &:hover{
    cursor:pointer;
  }
}

.loginLinkSpacer{
  margin-left:10px;
  margin-right:10px;
}

.btnSignout{
  background:none;
  border:none;
  font-family: "Montserrat", sans-serif !important;
  color: #84ac87 !important;
  font-weight: 500;

  &:hover{
    text-decoration: underline solid #84ac87;
    text-underline-offset:11px;
    text-decoration-thickness: 3px;
    transition: all 0.5s ease 0s;   
    cursor: pointer;       
  }
}

.mainMenuWrapper{

}

.navbarsticky{
  background: rgba(255, 255, 255, 0.99);
  height: 80px;
  box-shadow: rgb(116 116 116 / 5%) 0px 5px 15px;
  margin-top: 0px;
  width:100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 1rem;
  position: fixed;
  top: 0px;
  left:0px;
  z-index: 10;
  max-width: unset !important;
  padding-left:72px;
  padding-right: 72px;  
  

  // transition: all 0.5s ease 0s;  

  .logoContainer{
    top:0px;
    img{
      max-height: 65px;
      max-width: 131px;
    }
  }

  .nav-menu-sub{
    position:unset;
    width: auto;
    font-size:1rem;
  }


}

.navbarsticky + .sliderID{
  padding-bottom: 80px;  
}

.menuitem{
  position:relative;
  display:inline-block;
  margin-left:24px;

  &:hover{
    .dropdown-content {
      display:block;
    }  

    .menuLinkItem{
      text-decoration: underline solid #84ac87;
      text-underline-offset:22px;
      text-decoration-thickness: 6px;      
    }

  }

}

.menuLinkItem{
  &:hover{
    text-decoration: underline solid #84ac87;
    text-underline-offset:22px;
    text-decoration-thickness: 6px;
    transition: all 0.5s ease 0s;  
  }
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display:none;
  position: absolute;
  background-color: transparent;
  min-width: 160px;
  width:330px;
  z-index: 1;
  // margin-top:30px;
  transition: all 2s ease 0s;  
  background:#fff;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 10px;
  text-decoration: none;
  display: flex;
  font-size:0.9rem !important;
  justify-content: flex-start !important;
  margin-bottom:3px;

  &:hover{
    text-decoration: underline solid #84ac87;
    text-underline-offset:11px;
    text-decoration-thickness: 3px;
    transition: all 0.5s ease 0s;      
  }

}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: transparent;}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {background-color: #3e8e41;}