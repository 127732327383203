.contactListItem{
  display:flex;
  flex-direction: row;
  width:100%;
  padding:5px;
  border-bottom:1px solid rgba(0,0,0,0.1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  &:hover{
    background:rgba(240,240,240,0.3);
    cursor:pointer;
  }

  &.selected{
    background:rgba(240,240,240,0.3);
  }


  .contactListItemLeft{
    img{
      border-radius: 50%;
      max-height: 40px;
      margin-right:10px;
    }    
  }

  .contactListItemRight{
    display:flex;
    flex-direction: column;
    justify-content: center;

    .contactListItemTitle{
      font-size:17px;
      color:#FF004B;
      padding:0px;
      margin:0px;
      line-height: 17px;
    }

    .contactListItemTime{
      position:relative;
      top:0px;
      font-size: 11px;
      font-style: italic;
    }

  }

}