main.video{
  display:table;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box !important;
  // margin:0px !important;
  padding:0px !important;
}
.videoSectionWrapper{
  display: block;
  margin:0px !important;
  padding:0px !important;
  background:#e8e0db;
  width: 100%;
  height: 100%;
  padding:0px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */  

  .logoContainer{
    background: white;
    position: relative;
  }

  .navsubbarhome{
    background: #fff;
    height: 200px;
    width: 100%;
    display: block;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    font-size: 1.4rem;
    position: fixed;
    top: 0;
    z-index: 2;
    padding-bottom: 15px;
    transition: all 0.5s ease;    
  }

  .videoWrapper{
    position:relative;
    width: 100%;
    height:100%;
    background:black;
  }

  .player-wrapper {
    position: relative;
    display:block;
    width: 100%;
    height: calc(100vh - 8px);
    // padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

}