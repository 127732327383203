.resetMessage{
  margin-top:10px;
  font-size: 19px;
}

.btnFilterSearch.resetHome{
  max-width: 200px;
  text-align: center;
  

  &:hover{
    background-color:#3f5642;
    color:#fff;
  }
}

.newContentWrapper{
  display:flex;
  position:relative;
  width: 100%;
  height:auto;
  margin-top:33px;
  max-width: 1776px;
  margin-left:auto;
  margin-right:auto;


  .loginContentWrapper{
    display:flex;
    position: relative;
    flex-direction: column;
    background:#84ac87;
    width:100%;
    height:100%;
    padding-left:50px;
    padding-top:75px;
    padding-right:50px;
    padding-bottom:33px;
    margin-bottom:508px;

  }  
}


.loginSection{

  .edits-login{
    height:85px;
    border-radius: 0px;
    border:1px solid #c2c1bd;
    padding-left:15px;
    font-size:1.7rem;
    margin-bottom:20px;
    max-width: 600px;
    width: 100%;
    font-family: "Baskerville BT",serif;
    outline-color: #3f5642;

    &:last-of-type{
      margin-bottom:0px;
    }

  }

  .login-label{
    color:#fff;
    font-size:2.1rem;
    font-family: "Baskerville BT",serif;
  }


  .loginSectionFooter{
    display:flex;
    justify-content: space-between;
    

    .loginSectionFooterLeft{
      display:flex;
      flex-direction: column;
      font-size:2.1rem;
      font-family: "Baskerville BT",serif;
      color:#304232;
      padding-top:30px;
    }


    .loginSectionFooterRight{
      display:flex;
      align-items: flex-end;
      padding-top:30px;
    }

  }

  .sifremiUnuttumWrapper{
    position:relative;
    margin-top:5px;
    

    a{
      position:relative;
      color:#fff;
      text-decoration: underline;
      text-decoration-thickness: 2px;
      font-family: "Baskerville BT",serif;
      font-size:1.6rem;
      letter-spacing: 0px;
      margin-top:5px;
      
    }

  }

  .lastExpertDetailBtn{
    background:#506c53;
    font-family: "Baskerville BT",serif;
    font-size:1.9rem;
    font-weight: normal;
    height:86px;
    width:313px;

    &:hover{
      background:#3f5642;
      cursor: pointer;

    }

  }

  .linkForgetPasswordSignUp{
    color:#334837;
    text-decoration: underline;

    &:hover{
      color:#3f5642;
    }

  }


}




