.messageItem{
  position:relative;
  display:flex;
  flex-direction: row;
  width: 100%;
  margin-bottom:15px;

  &.messageRight{
    align-items: flex-start;
    justify-content: flex-end;
  }
  &.messageLeft{
    align-items: flex-start;
    justify-content: flex-start;
  }


  .messageImageLayer{
    display:flex;
    width: 40px;

    img{
      max-height: 40px;
      border-radius: 50%;
    }

  }

  .messageTextLayer{
    display: flex;
    flex-direction: column;

    .messageText{
      margin:0px;
      background: rgba(240, 240, 240, 0.9);
      padding:10px;
      border-radius: 10px;

      &.textRight{
        border-bottom-right-radius: 0px;
        
      }

      &.textLeft{
        border-bottom-left-radius: 0px;
      }
      

    }

    .messageTime{
      display:block;
      font-size:11px;
      color:rgba(0,0,0,0.3);
      &.textLeft {
        text-align: left;
      }
      &.textRight {
        text-align: right;
      }


    }
  }

}