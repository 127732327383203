.gridWrapperStep5{
  width:100%;
  display:grid;
  grid-template-columns: 1fr 1.5fr;

  .firstColumn{
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-end;    
  }

  .secondColumn{

    .sozlesmeWrapper{
      display:flex;
      margin-top:10px;
      font-size:1.7rem;
      font-weight: normal;
  
    }

  }
  


  .sectionTitle{
    text-align:center;
    font-family: "Baskerville BT",serif;
    font-size:1.7rem;
    font-weight: normal;
    color:#fff;
    width:100%;
    max-width: 600px;

  }
  .nextButton{
    fill:#506c53;
  }


  .edit-account-name{
    padding-right:138px;
  }

  .btn-add-to-socials{
    position:absolute;
    background:none;
    border:none;
    margin-right:10px;

    &:hover{
      cursor: pointer;
    }
  }

  .medyaDeneyimiRadioGroup {
    margin-top:0px;
  }

  .baglantiWrapper > .edits{
    margin:0px !important;
    width:calc(100% - 190px);
  }
  

}
.baglantiWrapper{
  display:flex;
  align-items: flex-end;
  justify-content: center;
  margin-top:9px !important;
  width: 100%;
  
}

.baglantiWrapper > .edits{
  margin:0px !important;
}

.contactWrapper{
  display:flex;
  width:100%;
  .edits{
    font-size:14px;
    margin-top:5px !important;
    margin-bottom:5px !important;
  }
}

.contactCheck{
  margin-right: 10px;
  width:160px;
}

.medyaDeneyimiRadioGroup{
  margin-bottom: 30px;
}

.contactWrapperInfo{
  max-width: 369px;
  width:100%;
  font-size:15px;
  margin-bottom:10px;
}
.infoAltyapi{
  margin-left:30px;
  font-size:1.4rem;
  font-weight: normal;
  color:#506c53;
  line-height: 1.4rem;
  font-family: "Baskerville BT",serif;

}
.altyapiSub{
  max-width: 400px;
  font-size:1.4rem;
  font-weight: normal;
  color:#506c53;
  line-height: 1.4rem;
  font-family: "Baskerville BT",serif;
  clear:both;
  margin-top:20px;
  display:block;
}

.groupContacts{
  display:flex;
  align-items: center;
}
.iletisimBilgileriCheckGroup{
  width:580px;
  display:flex;

  .checkContainer{
    width:auto !important;
    margin-right:10px;
  }
}
.groupContacts2{
  display: flex;
  justify-content: flex-end;
  width: 100%;


  .contactCheck{
    width:130px;
  }

  .edits{
    max-width: 500px;
  }

}

.step5SosyalMedya{
  margin-top:15px;
  padding-top:15px;
  overflow:hidden;
  overflow-y:auto !important;
  max-width: 600px !important;
  padding: 7px;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 300px;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #ebebeb;
  border-radius: 5px;  
  background: #fff;
  box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.1);  
}



.socialMedyaInfo{
  margin-left:5px;
  margin-top:1px;
  color:#666;
  max-width: calc(100% - 40px);
  width: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}

.onayTick{
  font-size:15px;
  margin-top:50px;
  position: relative;
  display: flex;
  font-weight: 500;
  justify-self: end;
  
}

.contactWrapper .checkContainer{
  width: auto;
  min-width: 100px;
  margin-bottom:0px;
  margin-right:10px;
}
.buttonBaglantiEkle{
  margin-left:5px;
  background:#384a60;
  color:#fff;
  padding-left:10px;
  padding-right: 10px;
  width:100px;
  height: 40px;
  font-weight: normal;
  font-size:15px;
  border-radius: 5px;
  outline:none;
  box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.1);  

  &:hover{
    cursor: pointer;
    background:#586a80;
  }
}

.iletisimBilgileriCheckGroupPre{
  display:flex;
}
.iletisimBilgileriCheckGroupPres{
  width:100% !important;
  display:grid;
  grid-template-columns:20px 1fr 200px 200px;
  grid-template-rows: 60px 1fr 1fr;   
  grid-template-areas:
  ". formBox0 formBox1 formBox2"
  "formBox3 formBox4 formBox5 formBox6"
  "formBox7 formBox8 formBox9 formBox10"
  "formBox11 formBox11 formBox12 formBox12";
  grid-gap:5px;
  width: 100%;
  height: 100%;

  .formBox11{
    grid-area: formBox11;
    padding-top:22px;
    padding-left:50px;
  }
  .formBox12{
    grid-area: formBox12;
    display:flex;
    justify-content: center;

    div{
      max-width: 300px;
    }

  }


  .formBox0{
    grid-area: formBox0;
    display: flex;
    align-items: center;
  }

  .formBox1 {
    grid-area: formBox1;
    display:flex;
    align-items: center;
    text-align: center;

    h4{
      line-height: 1.8rem;
    }
        
  }    
 
  .formBox2 {
    grid-area: formBox2;
    display:flex;
    align-items: center;    
    text-align: center;
    h4{
      line-height: 1.8rem;
    }


  }        

  .formBox3 {
    grid-area: formBox3;
    display:flex;
    align-items: center;
    justify-content: flex-end;    
  }     

  .formBox4 {
    grid-area: formBox4;
    display:flex;
    align-items: center;
    justify-content: center;        
  }     

  .formBox5 {
    grid-area: formBox5;
    display:flex;
    align-items: center;
    justify-content: center;    

    .checkContainer{
      margin-bottom:0px;
    }

  }     

  .formBox6 {
    grid-area: formBox6;
    display:flex;
    align-items: center;
    justify-content: center; 
    
    .checkContainer{
      margin-bottom:0px;
    }

    
  } 
  
  .formBox7 {
    grid-area: formBox7;
    display:flex;
    align-items: center;
    justify-content: flex-end;    
  }     

  .formBox8 {
    grid-area: formBox8;
    display:flex;
    align-items: center;
    justify-content: center;  
    margin-top:5px;
    
    .edit-logins{
      margin-top:10px;
    }
  }     
  .formBox9 {
    grid-area: formBox9;
    display:flex;
    align-items: center;
    justify-content: center;    

    .checkContainer{
      margin-bottom:0px;
    }


  }     
  .formBox10 {
    grid-area: formBox10;
    display:flex;
    align-items: center;
    justify-content: center;    

    .checkContainer{
      margin-bottom:0px;
    }

  }     



  





  .contactWrapper{
    width:100% !important;
  }
}



.medyaDeneyimiRadioGroupWrapper{
  width:100%;
  display: flex;
  justify-content: center;
}
.medyaDeneyimiRadioGroup{
  width:  100%;
  display: flex;
  justify-content: center;
}

.contactTercih{
  width:unset !important;
  display: flex;
  flex-direction: row;
  margin-bottom:40px;
}

.sectionBottom{
  margin-bottom: 20px;
}
.margin-top-x{
  margin-top:40px;
}

.ulSosyalHesaplar{
  list-style: none;
  position:relative;
  width: 100%;
}

.ulSosyalHesaplar li{
  display:flex;
  line-height: 15px;
  margin-top:5px;
  margin-bottom:5px;
  align-items: center;
  flex-direction: row;
  flex-wrap:nowrap;
  position:relative;
}

.ulSosyalHesaplar li .socialLine{
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.socialLine{
  margin-top:5px;
  margin-bottom:5px;
  display: flex;
  align-items:center;
}

.socialSelect{
  position:absolute !important;
  width:85px;
  margin-right: 44px !important;

}

.socialSelect div[class*="ValueContainer"] {
  height:40px;
  padding:3px;
}
.socialSelect div[class*="control"] {
  height:80px;
  border:none;
}
.socialSelect div[class*="IndicatorsContainer"] {
  &:hover{
    cursor: pointer;
  }
}

.socialSelectWrapper{
  padding:10px;
  display: flex;
  justify-content: center;
}

.socialSelect{
  border-radius: 0px;
  border:none;
  outline-color:none;
  margin-right:4px;

  

}

.react-dropdown-select{
  margin-top:10px;
  border-radius: 5px;
  border:1px solid #586a80;
  outline-color: #000 !important;
}

.react-dropdown-select-dropdown{
  border-radius: 5px;  
  font-size:15px;
}

.modalForm{
  position: relative;
  height: 85vh !important;
  display: block;
  padding: 60px;
  padding-bottom:20px;
  overflow: hidden;
  overflow-y: auto;  
}
.textCenter{
  text-align: center;
}

.fixclose{
  position: absolute;
  right: 40px;
  top: 10px;
  max-height: 35px;  
  z-index:9;
  background:none;
  border:none;
  width:35px;
  height: 35px;
  display:block;
  outline:none;
  cursor:pointer;

}

.closeButton{
  color:#506c53 !important;
  font-weight: lighter;
  height: 35px;
}
.on-bilgilendirme-formu-text u{
  &:hover{
    color:#506c53 !important;
  }
  
}

.popup-content{
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */    
}
.componentWrapper.step5-layer1{
  height: 232px !important;
  display: flex;
  justify-content: flex-end;
}