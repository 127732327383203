//Primary Text colors
$very-dark-desaturated-blue: #ffffff;
$soft-red: hsl(14, 88%, 65%);

// Background gradient:
$soft-violet: hsl(273, 75%, 66%);
$soft-blue: hsl(240, 73%, 65%);

//Neutral Text colors
$very-dark-grayish-blue: hsl(237, 12%, 33%);
$dark-grayish-blue: hsl(240, 6%, 50%);

//Dividers
$light-grayish-blue: #84ac87;



/* https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
/* Remove the focus indicator on mouse-focus for browsers that do support :focus-visible */
button:focus:not(:focus-visible) {
  outline: 0;
}

button:focus-visible {
  /* Draw a very noticeable focus style for
     keyboard-focus on browsers that do support
     :focus-visible */
  outline: 2px dashed $soft-red;
  outline-offset: 0px;
}

.container {
  max-width: 1440px;
  width: 100%;
}

dd{
  margin:0px;

  a{
    color: #84ac87 !important;  
    &:hover{
      color:#4b664d !important;
      text-decoration: underline;

    }
  }
}

.font-weight-bold {
  font-weight: 700;
  color: #84ac87 !important;
}



.faq__desc.show-description {
  height: auto;
  margin-bottom: 15px;
}

/* Arrows */

/* closed */
.faq__question-button::after {
  transition: transform 300ms ease-out;
}

/* open */
.font-weight-bold::after {
  transform: rotate(720deg);
}



.attribution {
  font-size: 16px;
  text-align: left;
  color: $light-grayish-blue;
  padding-top: 10px;
}
.attribution a {
  color: red !important;
}



.faq {
  width: 100%;
  padding-top: 0px;
  padding-bottom: 40px;
  display:flex;
  justify-content: center;

  &__title {
    margin: 0;
    padding-bottom: 25px;
  }

  &__question {
    max-width: 900px;
    width: 100%;
    border-bottom: 1px solid #f6f5f3;
  }

  &__question-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    font-weight: bold;
    background-color: transparent;
    padding: 0px;
    cursor: pointer;
    text-align: left;
    padding-top: 8px;
    padding-bottom: 8px;
    font-family: "Baskerville BT",serif;
    font-size:1.3rem;
    line-height: 2rem;
    color:#000000;
    font-weight: lighter;
    text-shadow: 0 0 #000;      


    &.font-weight-bold::after {
      transform: rotate(0deg);
    }

    // 
    &::after {
      content: url(../../assets/images/icon-arrow-down.svg);
      margin-right: 17px;
      transform: rotate(90deg);
    }

    &:hover {
      color: #84ac87;

      &::after{
        transform: rotate(0deg);
      }
      
    }
  }

  &__desc {
    margin-left: 0px;
    padding-right: 17px;
    margin-top: 0;
    margin-bottom: 0;
    height: 0;
    overflow: hidden;
    transition: all 200ms ease-out;
    font-family: "Baskerville BT",serif;
    font-size:1.1rem;
    line-height: 1.7rem;
    color:#000000;
    font-weight: lighter;
    text-shadow: 0 0 #000;      

  }
}

@media only screen and (max-width: 700px) {
  .container {
    /* added padding here to mobile container,
    so illustration__woman-desctop would not be cropped at the top */
    padding-top: 105px;
  }

  .component {
    max-width: 327px;
    background-position: top;
    flex-direction: column;
  }

  .illustration {
    &__box {
      display: none;
    }

    &__woman-desktop {
      display: none;
    }

    &__woman-mobile {
      display: block;
      margin: 0 auto;
      position: absolute;
      top: -105px;
      left: 12px;
    }
  }

  .faq {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 132px;
    padding-bottom: 48px;

    &__question {
      max-width: 279px;
    }

    &__question-button {
      width: 279px;
      font-size: 13px;
      line-height: 13px;

      &::after {
        padding-right: 0px;
      }
    }
  }
}
