.filterSectionWrapperID{
  display: flex;
  position:relative;
  width: 100%;
  height: 100%;
  margin-top:45px;
  margin-bottom:0px;
  align-items: center;
  // max-width: 1776px;
  margin-left:auto;
  margin-right:auto;
}
.filterSectionWrapper{
  display: flex;
  width: 100%;
  height: 352px;
  flex-direction: column;
  justify-content: center;
  margin:0px;
  padding:0px;
  background:#ededed;
  background-image:url('/assets/images/filterbg.png');
  background-repeat: no-repeat;
  background-position: center;
  align-items: center;
  // margin-bottom: 120px;

  .filterGridWrapper{
    position: relative;
    display:flex;
    max-width: 1336px;
    width:100%;
    justify-content: center;
  }

  .filterSectionTitle{
    font-size:1.7rem;
    font-family: "Baskerville BT",serif;
    font-weight: 500;
  }


  .btnFilterSearch{
    margin-top:13px;
    margin-left:15px;
    width: 200px !important;
    height: 55px;
    cursor:pointer;
    transition: background-color 0.3s;

    &:hover{
      background-color:#FF004B;
    }
  }
}




.forDropdown{
  & > div:first-child{
    width: 100%;
    display:flex;
    justify-content: center;
    border:none;
  }


  .react-dropdown-select-type-single{
    span:first-child{
      color:#fff !important;
      margin-top:4px
    }
    
  }  

  .react-dropdown-select{
    border:3px solid #fff !important;
    max-width: 465px;
    width:100%;
    height: 55px;
    background:#586a80;
    color:#fff;
    padding-left:15px;
    padding-right:15px;
    font-family: 'Baskerville BT',serif !important;
    font-weight: normal;
    font-size:15px;
    outline-color: #000 !important;
    margin-top:0px;
    padding-left:10px;

    &:focus{
      background:#384a60;
    }
    &:hover{
      border:3px solid #000 !important;
    }

    .react-dropdown-select-input{
      position:relative;
      display:flex;
      align-items: center;
      // top:12px;
      font-family: 'Baskerville BT',serif !important;

      color:#fff !important;

  
      &::placeholder{
        color:#fff !important;
        font-family: 'Baskerville BT',serif !important;
  
      }
    }

    .react-dropdown-select-item-selected {
      background: #384a60 !important;
    }    

  }
  
  .react-dropdown-select-dropdown{
    border-radius: 0px;  
    font-size:15px;
    font-family: 'Baskerville BT',serif;
  }  

  .react-dropdown-select-dropdown-handle{
    svg{
      fill:#fff;
      width: 40px;
      height: 40px;
    }
  }

}

.middleRedBox{
  display:flex;
  justify-content:center;
  align-items: center;
  width:390px;
  height:105px;
  background:#fd0202;
  color:#fff;
  font-family: "Baskerville BT",serif;
  font-size:1.8rem;
  line-height: 2.2rem;
  text-align:center;
  transition: background-color 0.3s;

  &:hover{
    background:#506c53;
    color:#fff;
    transition: background-color 0.3s;
  }
  
  &:first-of-type{
    margin-right:26px;
  }
  
}
