.messageLoader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #FF004B; /* Red-Pink :) */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  position:absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.messageList{
  position: relative;
  overflow-y: auto;
  height: 100%;
  border-bottom:1px solid rgba(0,0,0,0.2);
  padding:10px;
  padding-bottom:10px;
}
