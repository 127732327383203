.girisYaziSuccess{
  font-size: 28px !important;
  font-weight: 400 !important;
  color: #506c53 !important;
  text-shadow: 0 0 #506c53;
  line-height: 30px !important;
  /* margin-top: 20px; */
  margin-bottom: 90px !important;
  display:flex;
  flex-direction: column;
  align-items:center;
  text-align: center;
  
  span{
    font-size: 21px;
    margin-top:10px;
    margin-bottom:0px;
    text-align: center;
    color:#506c53;

    &:first-of-type{
      margin-top:40px;
    }
  }

}


.buttonWrapperSuccess{
  display:flex;
}

.btnFilterSearch{
  margin-top:10px;
  &:hover{
    background-color:#FF004B;
    cursor: pointer;
  }

  &:last-of-type{
    margin-left:10px;
  }
}