.mainWorksWrapper{
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  margin:0px;
  padding:0px;
    // margin-top:80px;
  padding-top:152px; 
  padding-bottom: 152px; //100
  // max-width: 1776px;
  position: relative;
  margin-left:auto;
  margin-right:auto;


  .mainWorksHeader{
    display:flex;
    flex-direction: column;
    margin-bottom:40px;

    .mainWorksHeaderImg{
      max-width:567px;
      width:100%;
      height: auto;
    }

    .mainWorksHeaderTitle{
      font-family: "Baskerville BT",serif;
      font-weight: normal;
      font-style: italic;
      font-size:3.7rem;
      color:#84ac87;
      letter-spacing: 10px;
      text-align: center;
      margin-top:10px;

    }

  }

  .workshopWrapper {
    position:relative;
    max-width: 100%;
    margin: 0 auto;
    display: grid;
    grid-gap: 50px;


    .workshop{

      .workshop-image{
        // max-width: 556px;
        // max-height: 556px;
        width:100%;
        height: auto;
        object-fit: cover;
      }

      .workshop-title-wrapper{
        position:relative;
        max-height: 150px;
        height:100%;

        .workshop-title{
          font-family: "Baskerville BT",serif;
          font-size: 2.7rem;
          color:#000;
          padding-top:30px;
          padding-bottom:30px;
          line-height: 2.7rem;

          .italic{
            display:block;
            font-style: italic;
            text-align: left;
            margin-left:-5px;
            color:#000;
          }

        }

      }

      .workshop-content-wrapper{
        position:relative;
        border-top:4px solid #84ac87;
        border-bottom:4px solid #84ac87;
        height: 400px;

        
        .workshop-content{
          padding-top:30px;
          font-family: "Baskervville", serif;
          font-size:1.4rem;
          color:#000000;
          color: #000;
          font-weight: lighter;
          text-shadow: 0 0 #000;          
          b{
            color:#000;
            font-weight: 600;
          }

          a{
            color:#000;
            text-decoration: underline;

            b{
              color:#000;
              &:hover{
                color:#84ac87 ;
  
              }              
            }

            &:hover{
              color:#84ac87 ;

            }
          }

        }
      }



      

    }
  }  
    /* Screen larger than 600px? 2 column */
    @media (min-width: 600px) {
      .workshopWrapper { grid-template-columns: repeat(2, 1fr); }
    }

    /* Screen larger than 900px? 3 columns */
    @media (min-width: 900px) {
      .workshopWrapper { grid-template-columns: repeat(3, 1fr); }
    }    
}

.aboutTitleLinks{
  &:hover{
    text-decoration: underline;
    text-decoration-color: #000;
    text-underline-offset: 1px;
    
  }
}

.margin-bottom-0{
  margin-bottom:0px;
}