.topHeader{
  position: relative;
  display:block;
  height:154px;
  width: 100%;
  padding:0px;
}


.headerContentWrapper{
  display:flex;
  flex-direction: column;
  justify-content: space-between;
}

.headerContentWrapper > h2{
  align-self: center;
  margin-top: 40px;
  margin-bottom: 20px;
}




.headerContentWrapper > h1{
  color:#ff964c;
  /* width: 400px; */
  font-weight: 400;
  align-self: center;
  margin-bottom: 0px;
  margin-top: 10px;
  margin-bottom: 20px;

}

.navbar{
  display:grid;
  grid-template-columns: repeat(auto-fit, minmax(100px,200px));
  grid-gap: 23px;
  grid-template-rows:50px 1fr;
  justify-content: center;
  align-items: center;

  div{
    box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.1);  
  }

}

.logoWrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;

  img{
    max-height: 95px;
    width: auto;
    height: 100%;    
  }
}

// nav:not(.navbarhome) > div:not(.logoWrapper){
//   width:100%;
//   height:52px;
//   background:#f1f1f1;
//   border-radius: 5px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }



.titleColor{
  color:#384a60;
}
.nav-links{
  text-decoration: none;
  font-size:24px;
  color:#c6b6ab;
  width: 100%;
  text-align: center;
  height: 100%;
  line-height: 52px;  
}

.nav-active{
  background:#384a60;
}

.nav-active > .nav-links{
  color:#384a60;
}

.closeSignUp{
  color:#384a60 !important;
  font-weight: lighter;
  position: absolute;
  right:30px;
  top:10px;
  max-height: 35px;
}