fast-design-system-provider{
  --neutral-foreground-rest: #506c53 !important;
  --background-color: #fff !important;
  --density: 0 !important;
  --design-unit: 4 !important;
  --base-height-multiplier: 10 !important;
  --base-horizontal-spacing-multiplier: 3 !important;
  --corner-radius: 3 !important;
  --outline-width: 1 !important;
  --focus-outline-width: 2 !important;
  --disabled-opacity: 0.3 !important;
  --type-ramp-minus-2-font-size: 10px !important;
  --type-ramp-minus-2-line-height: 16px !important;
  --type-ramp-minus-1-font-size: 12px !important;
  --type-ramp-minus-1-line-height: 16px !important;
  --type-ramp-base-font-size: 14px !important;
  --type-ramp-base-line-height: 20px !important;
  --type-ramp-plus-1-font-size: 16px !important;
  --type-ramp-plus-1-line-height: 24px !important;
  --type-ramp-plus-2-font-size: 20px !important;
  --type-ramp-plus-2-line-height: 28px !important;
  --type-ramp-plus-3-font-size: 28px !important;
  --type-ramp-plus-3-line-height: 36px !important;
  --type-ramp-plus-4-font-size: 34px !important;
  --type-ramp-plus-4-line-height: 44px !important;
  --type-ramp-plus-5-font-size: 46px !important;
  --type-ramp-plus-5-line-height: 56px !important;
  --type-ramp-plus-6-font-size: 60px !important;
  --type-ramp-plus-6-line-height: 72px !important;
  --accent-fill-active: #506c53 !important;
  --accent-fill-hover: #84ac87 !important;
  --accent-fill-rest: #739675 !important;
  --accent-foreground-cut-rest: #FFFFFF !important;
  --neutral-fill-input-active: #d3d3d3 !important;
  --neutral-fill-input-hover: #d3d3d3 !important;
  --neutral-fill-input-rest: #d3d3d3 !important;
  --neutral-focus: #eaeaea !important;
  --neutral-focus-inner-accent: #e18d8f !important;
  --neutral-outline-active: #eaeaea !important;
  --neutral-outline-hover: #eaeaea !important;
  --neutral-outline-rest: #eaeaea !important;
}
