.newFooter{
  background:#84ac87;
  position:relative;
  display:flex;
  width: 100%;
  height:715px;
  padding-top:83px;
  padding-left:107px;
  padding-bottom:0px;
  padding-right:107px;
  font-family: "Baskerville BT Italic",serif;
  color:#fff;
  

  .footerSection {
    display: grid;
    width:100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    max-width: 1776px;
    margin-right:auto;
    margin-left:auto;  
    
  }
    
  .foot1 { grid-area: 1 / 1 / 2 / 2; }
  .foot2 { 
    grid-area: 1 / 2 / 2 / 3;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p{
      color:#fff;
      font-style: italic;
      font-size:2rem;
      font-weight: normal;
      margin:0;
      line-height: 2rem;
      margin-bottom:5px;
      text-align: right;

      &:last-child{
        font-style: normal;
        font-size:1.8rem;
      }

    }

   }
  .foot3 {
     grid-area: 2 / 1 / 3 / 2;

     .socialList{
      list-style: none;
      color:#fff;
      li{
        color:#fff;
        font-style: normal;
        font-size:1.6rem;
        a{
          color:#fff;
          &:hover{
            text-decoration: underline;
          }
        }
        span{
          color:#fff;
          font-weight: 600;
        }

      }

     }

  }
  .foot4 { 
    grid-area: 2 / 2 / 3 / 3;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;

    .faqWrapper{
      display:flex;
      text-align: right;
      justify-content: flex-end;
      margin-bottom:80px;

      a{
        text-decoration: underline;
        color:#fff;
      }

      h5{
        font-size:1.8rem;
        color:#fff;
      }
    }

    .copyrightWrapper{
      display:flex;
      flex-direction: column;
      text-align: right;
      color:#fff;
      font-size:1.2rem;

      span{
        color:#fff;
      }


    }


  }  

  .footerbar-logo{

  }



}
