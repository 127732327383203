.searchSectionWrapper{
  display: flex;
  background:#efeded;
  width: 100%;
  height:100vh;
  padding-top:191px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */  
}
.webSectionTitleExpert{
  margin-top:0px;
  margin-bottom:20px;
  font-family: 'Baskerville BT',serif;
  font-size:3rem;
  color:#84ac87;
  text-shadow: 0 0 #84ac87;
}

.searchWrapper{
  display:grid;
  grid-template-columns:300px 1fr;
  grid-template-rows: 1fr;   
  grid-gap:30px;
  width: 100%;
  height: 100%; 
  max-width: 1336px;   
  padding:30px;
  font-size:1.3rem;
  
}

.inputWrapperSearch{
  padding-right: 10px;
  input{
    width: 100% !important;
  }
}

.searchContent{
  display:flex;
  flex-direction: column;
  width: 100%;
  p{
    color:#000;
    font-size:1.3rem;
    font-family: 'Baskerville BT',serif;

  }
  
}



.subContentSectionWrapperSearch{
  display: flex;
  flex-direction: column;
  align-items:center;
  width:100%;
  height:100%;
  padding-top:130px;
  padding-left:80px;
  padding-bottom:113px;
  padding-right:80px;

  .subContentTitle3{
    color:#84ac87;
    font-family: "Baskerville BT",serif;
    font-size: 2rem;
    line-height: 2rem;
    text-shadow: none;  
    text-align: left;
    display:flex;
    width: 100%;
  }

  .sectionSearchWrapper{
    display: flex;
    width:100%;
    .react-dropdown-select{
      margin-top:0px;
    }
    

    .edits-search{
      height:85px;
      border-radius: 0px;
      border:1px solid #c2c1bd;
      padding-left:15px;
      font-size:1.7rem;
      margin-bottom:20px;
      width: 600px;
      margin-right:30px;
      font-family: "Baskerville BT",serif;
      outline-color: #3f5642;
    }  
    
    .select-search{
      height:85px;
      border-radius: 0px;
      border:1px solid #c2c1bd;
      padding-left:15px;
      font-size:1.4rem;
      margin-bottom:20px;
      width: 500px;
      font-family: "Baskerville BT",serif;
      outline-color: #3f5642;
      margin-right:30px;

      .react-dropdown-select-input{
        display: none;
      }

    }

    .btnSearch{
      background:#84ac87;
      font-family: "Baskerville BT",serif;
      font-size:1.9rem;
      font-weight: normal;
      height:85px;
      width:313px;
      color:#fff;
      border:none;
  
      &:hover{
        background:#506c53;
        cursor: pointer;
  
      }
  
    }

  }

  
}


.searchTitle{
  color:#384a5f;
  font-size:24px;
}

.ulsearchList{
  list-style: none;
  font-size:1.3rem;
  max-width: 200px;
  justify-self: center;

  li{
    margin-bottom: 10px;
    color:#384a5f;
    font-weight: 500;
    font-size: 17px;
    font-family: 'Baskerville BT',serif;
    line-height: 17px;     
    text-align: center; 

    &.searchFilterTitle{
      color:#384a5f;
      font-weight: 500;
      font-family: 'Baskerville BT',serif;
      line-height: 17px;    
      font-size: 17px;    
    }

  }




}


.expertCount{
  font-weight: bold;
}

.expertContentP{
  font-family: 'Baskerville BT',serif;
}

.searchResultInfoText{
  font-family: 'Baskerville BT',serif;
  font-size:16px;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-bottom: 1px solid #8cb18e;
  

  li{
    display:inline-flex;
    align-items: center;

    a,span{
      position: relative;
      float: left;
      padding: 6px 12px;
      margin-left: -1px;
      line-height: 1.42857143;
      color: #000 !important;
      text-decoration: none;
      // background-color: #fff;
      // border: 1px solid #ddd;     
      
      &:hover{
        color:#84ac87;
      }
    }

    &:first-child>a,&:first-child>span{
      margin-left: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;      
    }

    &:last-child>a,&:last-child>span{
      margin-left: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }    

  }
}
.lastExpertsContentWrapper{
  display:grid;
  // grid-template-columns: repeat(5,auto);
  // grid-template-columns: repeat(auto-fill, minmax(min(100px,200px), 1fr));
  grid-template-columns: repeat(auto-fill, minmax(100px, 360px)) !important;
  grid-gap: 28px;
  padding-top:30px;
  width: 100%;
  justify-content: center;




  .lastExpertCard{
    position: relative;
    &:hover{
      cursor:pointer !important;

      .lastExpertImg{
        filter:unset;
      }
    }

    .lastExpertImg{
      margin-bottom:5px;
      width: 100%;
      height: 100%;
      max-width: 359px;
      max-height: 359px;
      border:8px solid #84ac87;
      filter:grayscale(100%);

      
    }

    .lastExpertName{
      display: flex;
      // justify-content: center;
      align-items: center;      
      color:#000;
      text-shadow: 0 0 #000;
      font-size:1.6rem;
      line-height:1.6rem;
      font-family: 'Baskerville BT',serif;
      margin-top:10px;
      text-align:left;
      width:100%;
      margin-bottom:0px;

      &.selected{
        color:#84ac87 !important;
        text-shadow: 0 0 #84ac87;
      }
            
    }

    .liveInfo{
      font-family: 'Baskerville BT',serif;   
      font-size:1.5rem;  
      color:#000;
      font-style: italic; 
      text-shadow: 0 0 #000;

      &.selected{
        color:#84ac87 !important;
        text-shadow: 0 0 #84ac87;
      }

    }

    .lastExpertTitle{
      color:#586a80;
      font-family: 'Baskerville BT',serif;
      font-weight: normal;
      font-style: italic;
      font-size: 16px;
      min-height: 50px;
      text-align:center;
    }


    .expertButtonWrapper{
      display:flex;
      justify-content: center;
    }

    .lastExpertDetailBtn{
      // margin-top:20px;
      font-family: 'Baskerville BT',serif;
      padding-top: 6.5px;
      padding-bottom: 6.5px;      
      border-radius: 0;
      background:#384a5f;

      &:hover{
        background:#586a80 !important;
        color:#fff;
        transition: all 0.3s ease-out;
        cursor:pointer;
      }

    }
    
  }

}



.paginationWrapper{
  display:flex;
  justify-content: center;
  margin-top:70px;

  .disabled{
    color:lightgrey; 
  }

}


.expertProfileImg{
  box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.20);
  margin-bottom:25px;
  width: 437px;
  height: 476px;
  border: 8px solid #84ac87;

}
.expertLoading{
  display:flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  p{
    width: 100%;
    text-align: center;
    font-size: 1.3rem;
    font-weight: 500;

  }
}

.expertSocialMedyaUl{
  list-style: none;
  margin-left:0px !important;

  .socialMedyaInfo{
    margin-left:0px;
    color:#000;
  }

  .socialLine{
    margin-top:0px;
    margin-bottom:0px;
  }

  li{
    margin-bottom:0px;
  }
}

.expertIntroUl{
  list-style: none;
  margin-left:0px !important;
  font-size: 1.4rem;
  font-family: 'Baskerville BT',serif;


  li{
    display:grid;
    grid-template-columns: 250px 1fr;
    margin-top:10px;
    font-size: 1.4rem;
    text-shadow: 0 0 #000;      
    font-family: 'Baskerville BT',serif;
    color: #000;

    div{
      display:flex;
      align-items: center;
      font-size: 1.4rem;
      text-shadow: 0 0 #000;      
      font-family: 'Baskerville BT',serif;
      color: #000;

      span{
        font-size: 1.4rem;
        text-shadow: 0 0 #000;      
        font-family: 'Baskerville BT',serif;
        color: #000;
      }
    }

    strong{
      margin-right:10px;
      color: #000;;
    }

    label{
      color: #000;
      font-size: 1.4rem;
      text-shadow: 0 0 #000;      
      font-family: 'Baskerville BT',serif;
      margin-bottom:0px;      
    }

  }
}

.clean{
  display: block;
  height: 60px;
  position: relative;
}

.lastExpertContactInfo{
  display: flex;
  flex-direction: column;
  color: #000 !important;
  font-size: 1.4rem;
  text-shadow: 0 0 #000;    
  margin-top:10px;
  text-align: left;

  span{
    color: #000 !important;
    font-size: 1.4rem;
    text-shadow: 0 0 #000;    
  
  }

}

.contactButtons{
  display:inline-flex;
  justify-self: center;
  align-self: center;
  margin-bottom:10px;

  &:hover{
    background:#FF004B;
    cursor:pointer;
  }

}

.expertWrapper{
  display: grid;
  border-top:4px solid #84ac87;
  grid-template-columns: 450px 1fr;
  grid-template-rows: 1fr;
  grid-gap: 30px;
  width: 100%;
  height: 100%;
  font-size: 1.3rem;
  padding-top:85px;   
  margin-top:30px;
}

.expertpublishs{
  color:#000;

  &:hover{
    color:#506c53;
  }
}

.expertContent{
  p{
    color:#000;
    font-size:1.4rem;
    font-family: 'Baskerville BT',serif;
    text-shadow: 0 0 #000;      

  }

  ul{
    list-style: none;
    margin-left:2px;

    li{
      color:#000;
      text-shadow: 0 0 #000;      
      font-size:1.4rem;
      font-family: 'Baskerville BT',serif;   
    }
  }
  
}

.expertListSticky{
  position: sticky;
  top: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .editsfilter{
    margin-top:0px;
  }

  .editfiltericon {
    position: absolute;
    left: 14px;
    top: 8px;
  }

  .buttonwrapper{
    width: auto !important;

    .btnFilterexpert{
      &:hover{
        cursor:pointer;
        background: #FF004B;
      }
    }
  }

  .react-dropdown-select {
    position: relative;
    margin-top: 0px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #3f5642;
    outline-color: #000 !important;
    max-width: 300px;
    width: 100%;
    font-weight: 300;
    font-size: 15px;
    font-family: 'Baskerville BT',serif;
    background:#fff;

    
  }

  .react-dropdown-select-input{
    position:absolute;
    top:5px;
    width: 100%;

    ::placeholder{
      font-size:15px;
    }
  }

  div[class*="ContentComponent"]{
    padding-left:5px;
    padding-right: 5px;
  }
        

}

.expertSectionTitle{
  font-family: "Baskerville BT",serif;
  font-size:1.8rem;
  line-height: 2rem;
  color:#000000;
  font-weight: bold;
  

}

  .ulexpertList{
    list-style: none;
    font-size:1.4rem;

    li{
      margin-bottom: 10px;
      color:#000;
      font-weight: 500;
      font-size: 17px;
      font-family: 'Baskerville BT',serif;
      line-height: 17px;     
      text-align: center; 

      &.expertFilterTitle{
        color:#000;
        font-weight: 500;
        font-family: 'Baskerville BT',serif;
        line-height: 2.2rem;    
        font-size: 2.2rem;  

        .lastExpertName{
          margin-top:20px;
          color:#000;
          font-size:2rem;
          text-shadow: 0 0 #000;    
          font-family: 'Baskerville BT',serif;
          font-weight: lighter;
          margin-bottom:5px;
          text-align:left;
          display: flex;
          justify-content: flex-start;
          align-items: center;   
          
          
        }
        .lastExpertTitle{
          color:#000;
          margin-top:10px; 
          font-family: 'Baskerville BT',serif;
          font-weight: normal;
          font-style: italic;
          font-size:1.8rem;
          min-height: 50px;
          text-align:left;
        }

        .lastExpertContactTitle{
          color:#000;
          font-size:1.8rem;
          text-shadow: 0 0 #000;    
          font-family: 'Baskerville BT',serif;
          font-weight: bold;          
          margin-bottom:5px;
          text-align:left;
          display: flex;
          justify-content: flex-start;
          align-items: center;      
        }        
    

      }

    }


    .expertFilterContent{
      .inputWrapper.web{
        div{
          &:first-of-type{
            width:100%;
          }
        }
      }
    }


  }


  .paginationLi{
    display:inline-flex;
    padding-bottom:4px;
    font-weight: 500;
    color:#000;
    font-size:1.8rem;
    text-shadow: 0 0 #000;    
    font-family: 'Baskerville BT',serif;
  }


  .paginationLiActive{
    a{
      color:#3f5642 !important;
      font-weight: bold;
    }

  }


  .pageNext{
    width:30px;
    height:30px;
    background:url('/assets/images/arrow-right.png');
    background-repeat: no-repeat;
    display: block;
    background-position: center;
    background-size: contain;    
    margin-bottom:10px;
    margin-left:30px;
  }

  .pagePrev{
    width:30px;
    height:30px;
    background:url('/assets/images/arrow-left.png');
    background-repeat: no-repeat;
    display: block;    
    background-position: center;
    background-size: contain;
    margin-bottom:10px;
    margin-right:30px;
  }