.sectionHeaderContentWrapper{
  display:flex;
  flex-direction: column;
  align-items: center;
  

  .sectionHeaderTitle{
    color:#fff;
    font-size:2.1rem;
    font-family: "Baskerville BT",serif;    
    margin-bottom:83px;
  }
  

}





.headerContentWrapper > h1{
  color:#ff964c;
  /* width: 400px; */
  font-weight: 400;
  align-self: center;
  margin-bottom: 0px;
  margin-top: 10px;
  margin-bottom: 20px;

}

.sectionNavbar{
  display:grid;
  width:100%;
  grid-template-columns: repeat(auto-fit, minmax(100px,247px));
  grid-gap: 38px;
  // grid-template-rows:50px 1fr;
  justify-content: center;
  align-items: center;

  div{
    box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.1);  
    height:88px !important;
    max-width: 247px;
    width:100%;
    display:flex;
    align-items: center !important;
  }


  .nav-links{
    text-decoration: none;
    font-size:2rem;
    color:#fff;
    width: 100%;
    text-align: center;
    height: 100%;
    background: #668e69;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Baskerville BT",serif;
    &:hover{
      background:#506c53 !important;  
    }
    
  }
  
  .nav-active{
    background:#506c53 !important;
  }
  
  .nav-active > .nav-links{
    color:#fff;
  }
  
  

}

