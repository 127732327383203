#makalelerIDWrapper{
  display:flex;
  width: 100%;
  height:100%;
  background:#f6f5f3;
}
.articlesSectionWrapper{
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(2, 1fr);
  padding-left:144px;
  padding-right:144px;
  padding-top:135px;
  padding-bottom:100px;
  
  grid-gap: 65px;
  // margin-bottom:100px;
  // padding-bottom:70px;

  .articleItem{
    display:flex;
    flex-direction: column;
    justify-content:  flex-start;

    .articleItemTitle{
      color:#84ac87;
      font-family: "Baskerville BT",serif;
      font-size: 4rem;
      font-style: italic;   
      text-decoration: underline; 
      text-underline-offset: 10px;  
      text-decoration-thickness: 4px;
      margin-bottom:0px;
      margin-top:10px;                 
    }

    .articleItemContent{
      font-family: "Baskervville", serif;
      font-size:1.4rem;
      color:#000000;
      color: #000;
      font-weight: lighter;
      text-shadow: 0 0 #000;      
      margin-top:30px;          
    }

  }

  .articleTitleLink{
    &:hover{
      h2{
        color:#000 !important;
      }
      

    }
  }

}
