.homeWrapper{
  position: relative;
  display: table;  
  width:100%;
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */    
}
.newContentLayerWrapper{
  display:flex;
  flex-direction: column;
  background: #fff !important;
}

.bgGrey{
  background: #f6f5f3 !important;
}

.spacebottom{
  margin-bottom:55px;
}

.spacerTop75{
  margin-top:75px;
}

.subContentSectionWrapper{
  display: flex;
  flex-direction: column;
  align-items:center;
  width:100%;
  height:100%;

  .subContentTitle{
    color:#84ac87;
    font-family: "Baskerville BT",serif;
    font-style: italic;
    font-size: 4rem;
    line-height: 4rem;
    text-shadow: none;
    
  }
  .subContentTitle2{
    color:#84ac87;
    text-shadow: none;
    font-family: "Baskerville BT",serif;
    font-style: italic;
    font-size: 4rem;
    line-height: 4rem;
    margin-top:60px;
    margin-bottom:60px;
  }

  .subContentParagraph{
    font-family: "Baskervville", serif;
    font-size:1.4rem;
    line-height: 2rem;
    color:#000000;
    font-weight: lighter;
    text-shadow: 0 0 #000;      
    max-width: 1255px;
    width:100%;
    text-align: center;
  }

  .ulProjeEkibi{
    display:flex;
    flex-direction: column;
    list-style: none;
    padding:0px;
    margin:0px;
    margin-bottom:100px;

    li{
      font-family: "Baskervville", serif;
      font-size:1.8rem;
      line-height: 1.8rem;
      color:#000000;
      font-weight: lighter;
      text-shadow: 0 0 #000;            
      margin-bottom:10px;

      &:hover{
        cursor:pointer;
        color:#84ac87;
      }

      &.selected{
        color:#84ac87;
        text-shadow: 0 0 #84ac87;  
      }

    }

  }

  .sectionCVWrapper{
    display:flex;
    position: relative;
    width:100%;
    height:100%;
    padding-left:110px;
    padding-right:110px;
    border-top:3px;
    padding-top:32px;
    padding-bottom:32px;
    border-top:4px solid #84ac87;
    border-bottom:4px solid #84ac87;
    margin-bottom:270px;


    .cvImageLayer{
      position:relative;
      margin-right:32px;

      .cvImg{
        max-width: 450px;
        max-height: 489px;
        min-width: 450px;
        width:100%;
        height: auto;
        border:8px solid #84ac87;
      }

    }

    .cvContent{
      position:relative;

      .cvTitle{
        font-family: "Baskervville", serif;
        font-size:2rem;
        line-height: 2rem;
        color:#000000;
        text-shadow: 0 0 #000;      
      }

      .jobWrapper{
        font-family: "Baskervville", serif;
        font-size:1.4rem;
        line-height: 1.6rem;
        color:#000000;
        font-weight: lighter;
        text-shadow: 0 0 #000;      
      }

      .cvtext{
        font-family: "Baskervville", serif;
        font-size:1.4rem;
        line-height: 2rem;
        color:#000000;
        font-weight: lighter;
        text-shadow: 0 0 #000;      
      }

    }


  }

}

.cvController{
  position: absolute;
  bottom: -60px;
  right: 25px;
  display: flex;
  
  .btn-cv-controller{
    position: relative;
    border:none;
    background: none;
    margin-left:35px;

    &:hover{
      cursor: pointer;
    }
    
    img{
      height: 30px;
    }
  }
  
}
