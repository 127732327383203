.homeWrapper{
  position: relative;
  display: table;  
  width:100%;
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */    
}
.newContentLayerWrapper{
  display:flex;
  flex-direction: column;
  background: #fff !important;
}

.bgGrey{
  background: #f6f5f3 !important;
}

.spacebottom{
  margin-bottom:55px;
}

.spacerTop75{
  margin-top:75px;
}

.subContentSectionWrapper{
  display: flex;
  flex-direction: column;
  align-items:center;
  width:100%;
  height:100%;

  .imgMentorluk{
    max-width: 755px;
    max-height: 741px;
    width: 100%;
    height: auto;
    margin-bottom:30px;
  }  

  .subContentTitle{
    color:#84ac87;
    font-family: "Baskerville BT",serif;
    font-style: italic;
    font-size: 4rem;
    line-height: 4rem;
    text-shadow: none;
    
  }
  .subContentTitle2{
    color:#84ac87;
    text-shadow: none;
    font-family: "Baskerville BT",serif;
    font-style: italic;
    font-size: 4rem;
    line-height: 4rem;
    margin-top:60px;
    margin-bottom:60px;
  }

  .iletisimParagragh{
    font-family: "Baskerville BT",serif;
    font-size:1.3rem;
    line-height: 2rem;
    color:#000000;
    font-weight: lighter;
    text-shadow: 0 0 #000;      
    max-width: 1255px;
    width:100%;
    text-align: center;
    margin-bottom:30px;
    word-wrap: break-word;

    b{
      font-weight: bold;
      color:#000;
    }

    

  }

  .SSSLink{
    position: relative;
    display: flex;
    font-family: "Baskerville BT",serif;
    font-size:2rem;
    line-height: 2rem;
    color:#84ac87;
    font-weight: lighter;
    text-shadow: 0 0 #84ac87;      
    padding-top:0px;
    padding-bottom:30px;
    transition: all 0.5s ease 0s;  
    text-transform: uppercase;


    &:hover{
      text-decoration: underline;
      text-underline-offset: 10px;
      
    }



  }

  .iletisimUl{
    display:flex;
    list-style: none;
    margin-bottom:120px;

    li{
      margin-right:20px;
      font-size:2rem;

      &:hover{
        i{
          color:#84ac87;
        }
        
      }
    }
  }

}
